import { apiToNumber, apiToString, apiToGeometry, apiToFloatNumber, apiToDate, stringToApi, numberToApi, dateToApi } from "@pasport/react-common";
import {MapParkingDto, ParkingDto, ParkingParkovani, ParkingPovrch} from "../../interfaces/ParkingDto"
import {MapParkingApiDto, ParkingApiInDto, ParkingApiOutDto, ParkingNameApiDto} from "./ParkingApi.dto"

const apiToParkingParkovani = (api: string): ParkingParkovani => api as ParkingParkovani;
const apiToParkingPovrch = (api: string): ParkingPovrch => api as ParkingPovrch;

export const mapApiToParkingDto = (api: ParkingApiInDto): ParkingDto => {
    return {
        id: apiToNumber(api.parkoviste_id),
        oznaceni: apiToString(api.oznaceni),
        nazev: apiToString(api.nazev),
        komunikace: apiToString(api.komunikace),
        parkovani: apiToParkingParkovani(api.parkovani),
        vlastnik: apiToString(api.vlastnik),
        povrch: apiToParkingPovrch(api.prevazujici_povrch),
        geometry: apiToGeometry(api.geom),
        geometryString: apiToString(api.geom),
        stav: apiToString(api.stav),
        plocha: apiToFloatNumber(api.plocha_m2) as number,
        pocetMist: apiToNumber(api.pocet_mist),
        pocetMistProInvalidy: apiToNumber(api.mista_invalidy),
        pocetVyhrazenychMist: apiToNumber(api.mista_vyhrazene),
        datumVystavby: apiToDate(api.datum_vystavby),
        datumMapovani: apiToDate(api.datum_mapovani),
        datumAktualizace: apiToDate(api.datum_aktualizace),
        datumPosledniZavady: apiToDate(api.datum_posl_zavady),
        datumPosledniUdrzby: apiToDate(api.datum_posl_servisu),
        poznamka: apiToString(api.poznamka),
        dalsiInfo: apiToString(api.dalsi_info),
        uzemiId: apiToNumber(api.uzemi_id) || apiToNumber(api.uzemi_id_par) || undefined,

    }
}

export const mapApiToMapParkingDto = (api: MapParkingApiDto): MapParkingDto => {
    return {
        id: api.parkoviste_id,
        oznaceni: api.oznaceni,
        nazev: api.nazev,
        komunikace: api.komunikace,
        parkovani: apiToParkingParkovani(api.parkovani),
        uzemiId: api.uzemi_id,
        uzemiNazev: api.ku,
        geometry: apiToGeometry(api.geom) ?? [],//FIXME
    }
}

export const mapParkingDtoToApi = (dto: ParkingDto): ParkingApiOutDto => {
    return {
        oznaceni: stringToApi(dto.oznaceni),
        nazev: stringToApi(dto.nazev),
        komunikace: stringToApi(dto.komunikace),
        parkovani: stringToApi(dto.parkovani),
        vlastnik: stringToApi(dto.vlastnik),
        prevazujici_povrch: stringToApi(dto.povrch),
        stav: stringToApi(dto.stav),
        IXpocet_mist: numberToApi(dto.pocetMist),
        IXmista_invalidy: numberToApi(dto.pocetMistProInvalidy) + "",
        IXmista_vyhrazene: numberToApi(dto.pocetVyhrazenychMist),
        DXdatum_vystavby: dateToApi(dto.datumVystavby),
        DXdatum_mapovani: dateToApi(dto.datumMapovani),
        DXdatum_aktualizace: dateToApi(dto.datumAktualizace),
        dalsi_info: stringToApi(dto.dalsiInfo),
        poznamka: stringToApi(dto.poznamka),

    }
}


export const mapApiToParkingNameDto = (response: ParkingNameApiDto): string => {
    return response.oznaceni;
}
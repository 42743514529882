import {LocationPoint} from "maps-api/lib/core/LocationPoint";
import {ROAD_POVRCH_LIST, ROAD_USEK_LIST, RoadProvoz, RoadUmisteni} from "../consts/Road";

export enum RoadTyp {
    VOZOVKA = "vozovka",
    CHODNIK = "chodník",
    CYKLOSTEZKA = "cyklostezka",
    CHODNIK_CYKLOSTEZKA = "chodník + cyklostezka",
}

export enum RoadTrida {
    TRIDA_1 = "MK I. třídy",
    TRIDA_2 = "MK II. třídy",
    TRIDA_3 = "MK III. třídy",
    TRIDA_4 = "MK IV. třídy",
    UCELOVA_KOMUNIKACE = "účelová kom.",
}

export type RoadUsek = typeof ROAD_USEK_LIST[number];
export type RoadPovrch = typeof ROAD_POVRCH_LIST[number];

export interface RoadDto {
    id: number,
    oznaceni: string,
    nazev: string,
    typ: RoadTyp,
    trida: RoadTrida,
    usek: RoadUsek,
    vlastnik: string,
    povrch: RoadPovrch | null,
    stav: string,
    delka: number | null,
    plocha: number | null,
    prumernaSirka: number | null,
    provoz: RoadProvoz,
    pocetPruhu: number | null,
    umisteni: RoadUmisteni,
    pocetMostichObjektu: number | null,
    pocetParkovist: number | null,
    parcely: string,
    vlastniciParcel: string,
    datumVystavby: Date | null,
    datumMapovani: Date | null,
    datumAktualizace: Date | null,


    geometry: LocationPoint[][] | null,
    geometryString: string,
    datumPosledniZavady: Date | null,
    datumPosledniUdrzby: Date | null,
    zimniUdrzba: string,
    dalsiInformace: string,

    poznamka: string,
}

export type MapRoadDto = {
    id: number,
    oznaceni: string,
    nazev: string,
    typ: RoadTyp,
    trida: RoadTrida,
    usek: string,
    uzemiId: number,
    uzemiNazev: string,
    geometry: LocationPoint[][],
}


export type DiaryEntryAdvancedDetailForRoad = {
    uzemiId: number,
    uzemiNazev: string,
    komunikaceId: number,
    oznacenikomunikace: string,

}


export type RoadSearchResultDto = {
    id: number,
    oznaceni: string,
    uzemiId: number,
    uzemiNazev: string,
}



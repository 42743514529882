import {BridgeDto} from "../../interfaces/BridgeDto";
import {BridgeItem} from "./BridgeItem";
import {useBridgeFilter} from "./BridgeFilter";
import {BridgeListFilter} from "./BridgeFilter/BridgeListFilter";
import { Spacing, ListContainer } from "@pasport/react-common";
import { Stack } from "@pasport/react-ui";


interface RoadListProps {
    items: BridgeDto[],
    getLink: (sign: BridgeDto) => string
}

export const BridgeList = ({items, getLink}: RoadListProps) => {


    const {
        filteredItems,
        ...restProps
    } = useBridgeFilter(items);

    return <>
        <BridgeListFilter {...restProps}/>
        <Spacing space={2}/>
        <ListContainer
            nextPage={100}
            items={filteredItems}
            render={(renderItems) => (
                <Stack space={2}>
                    {renderItems.map(bridge => <BridgeItem value={bridge} key={bridge.id} link={getLink(bridge)}/>)}


                </Stack>
            )}
            emptyLabel={'Filtrům neodpovídá žádný mostní objekt.'}
        />

    </>
}
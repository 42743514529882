import {useNavigate} from "react-router-dom";
import {DiaryParametricScreen} from "@pasport/react-common";
import {ParkingDto} from "../interfaces/ParkingDto";
import {
    createParkingDiaryEntry, deleteParkingDiaryEntry,
    deleteParkingDiaryPhoto, getParkingDiaryEntryDetail, getParkingDiaryEntryRelatedList,
    getParkingDiaryPhotoList, updateParkingDiaryEntry,
    uploadParkingDiaryPhoto
} from "@apis/Parking";
import {useAlert, useBreadcrumbs} from "@pasport/react-common";
import {getAbsoluteRoutePath, useUrlParams} from "../core/routing";

interface ParkingDiaryScreenProps {
    detail: ParkingDto
}

export const ParkingDiaryScreen = ({detail}: ParkingDiaryScreenProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();
    const {uzemiId, parkovisteId, parkovisteHlaseniId} = useUrlParams();

    useBreadcrumbs({
        to: getAbsoluteRoutePath('parkovisteHlaseni', {uzemiId, parkovisteId, parkovisteHlaseniId}),
        label: 'Deníkové hlášení'
    });

    return <DiaryParametricScreen
        identifier={parkovisteHlaseniId}
        title={`Deníkové hlášení parkoviště ${detail.oznaceni}`}
        getLink={(diaryEntry) => getAbsoluteRoutePath('parkovisteHlaseni', {
            uzemiId,
            parkovisteId,
            parkovisteHlaseniId: diaryEntry.id
        })}

        dispatchDetail={() => getParkingDiaryEntryDetail(parkovisteHlaseniId, parkovisteId, uzemiId)}
        dispatchChildren={() => getParkingDiaryEntryRelatedList(parkovisteHlaseniId, parkovisteId, uzemiId)}

        dispatchUpdate={(diaryEntry) => updateParkingDiaryEntry(diaryEntry, parkovisteId, uzemiId)}
        onUpdateSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení parkoviště bylo upraveno."});
        }}

        dispatchDelete={() => deleteParkingDiaryEntry(parkovisteHlaseniId, parkovisteId, uzemiId)}
        onDeleteSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení parkovště bylo odstraněno."});
            navigate(
                getAbsoluteRoutePath('parkoviste', {
                    uzemiId,
                    parkovisteId,
                })
            );
        }}

        dispatchCreate={(diaryEntry) => createParkingDiaryEntry({
            ...diaryEntry,
            parentId: parkovisteHlaseniId
        }, parkovisteId, uzemiId)}
        onCreateSuccess={(diaryEntryId) => {
            addSuccessAlert({message: "K závadě bylo přidáno hlášení."});
            navigate(
                getAbsoluteRoutePath('parkovisteHlaseni', {
                    uzemiId,
                    parkovisteId,
                    parkovisteHlaseniId: diaryEntryId
                })
            );
        }}


        dispatchPhotoList={() => getParkingDiaryPhotoList(parkovisteHlaseniId, parkovisteId, uzemiId)}
        dispatchPhotoUpload={(file) => uploadParkingDiaryPhoto(file, parkovisteHlaseniId)}
        dispatchPhotoDelete={(file) => deleteParkingDiaryPhoto(file, parkovisteHlaseniId, parkovisteId, uzemiId)}
    />
}
export const ROAD_TYPE_LIST = ["vozovka", "chodník", "cyklostezka", "chodník + cyklostezka"] as const;

export const ROAD_TRIDA_LIST = ["MK I. třídy", "MK II. třídy", "MK III. třídy", "MK IV. třídy", "účelová kom."] as const;

export const ROAD_USEK_LIST = ["pozemní těleso", "mostní", "tunelový"] as const;

export const ROAD_POVRCH_LIST = ["asfalt (živice)", "beton", "dlažba", "recykláž (R-materiál)", "písek, štěrkopísek", "šotolina (štěrk)", "nezpevněno", "jiný", "nezjištěno"] as const;

export type RoadProvoz = "jednosměrný" | "obousměrný" | "neurčeno" | "pěší" | "cyklistický" | "pěší + cyklistický";

export const ROAD_PROVOZ_LIST: readonly RoadProvoz[] = ["neurčeno", "jednosměrný", "obousměrný"] as const;

export const ROAD_UMISTENI_LIST = ["samostatně", "k místní komunikaci", "k silnici"] as const;
export type RoadUmisteni = typeof ROAD_UMISTENI_LIST[number] | '-';
import {useNavigate} from "react-router-dom";
import React from "react";
import {DiaryParametricScreen} from "@pasport/react-common";
import {BridgeDto} from "../interfaces/BridgeDto";
import {
    createBridgeDiaryEntry,
    deleteBridgeDiaryEntry, deleteBridgeDiaryPhoto,
    getBridgeDiaryEntryDetail,
    getBridgeDiaryEntryRelatedList, getBridgeDiaryPhotoList,
    updateBridgeDiaryEntry,
    uploadBridgeDiaryPhoto
} from "@apis/Bridge";
import {useAlert, useBreadcrumbs} from "@pasport/react-common";
import {getAbsoluteRoutePath, useUrlParams} from "../core/routing";

interface BridgeDiaryScreenProps {
    detail: BridgeDto
}

export const BridgeDiaryScreen = ({detail}: BridgeDiaryScreenProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();
    const {uzemiId, mostId, mostHlaseniId} = useUrlParams();

    useBreadcrumbs({
        to: getAbsoluteRoutePath('mostHlaseni', {uzemiId, mostId, mostHlaseniId}),
        label: 'Deníkové hlášení'
    });

    return <DiaryParametricScreen
        identifier={mostHlaseniId}
        title={`Deníkové hlášení mostního objektu ${detail.oznaceni}`}
        getLink={(diaryEntry) => getAbsoluteRoutePath('mostHlaseni', {
            uzemiId,
            mostId,
            mostHlaseniId: diaryEntry.id
        })}

        dispatchDetail={() => getBridgeDiaryEntryDetail(mostHlaseniId, mostId, uzemiId)}
        dispatchChildren={() => getBridgeDiaryEntryRelatedList(mostHlaseniId, mostId, uzemiId)}

        dispatchUpdate={(diaryEntry) => updateBridgeDiaryEntry(diaryEntry, mostId, uzemiId)}
        onUpdateSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení mostního objektu bylo upraveno."});
        }}

        dispatchDelete={() => deleteBridgeDiaryEntry(mostHlaseniId, mostId, uzemiId)}
        onDeleteSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení mostního objektu bylo odstraněno."});
            navigate(
                getAbsoluteRoutePath('most', {
                    uzemiId,
                    mostId,
                })
            );
        }}

        dispatchCreate={(diaryEntry) => createBridgeDiaryEntry({
            ...diaryEntry,
            parentId: mostHlaseniId
        }, mostId, uzemiId)}
        onCreateSuccess={(diaryEntryId) => {
            addSuccessAlert({message: "K závadě bylo přidáno hlášení."});
            navigate(
                getAbsoluteRoutePath('mostHlaseni', {
                    uzemiId,
                    mostId,
                    mostHlaseniId: diaryEntryId
                })
            );
        }}


        dispatchPhotoList={() => getBridgeDiaryPhotoList(mostHlaseniId, mostId, uzemiId)}
        dispatchPhotoUpload={(file) => uploadBridgeDiaryPhoto(file, mostHlaseniId)}
        dispatchPhotoDelete={(file) => deleteBridgeDiaryPhoto(file, mostHlaseniId, mostId, uzemiId)}
    />
}
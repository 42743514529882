import {Route, Routes} from "react-router-dom";
import React from "react";
import {RoadScreen} from "../screens/RoadScreen";
import {getRoadDetail} from "@apis/Road";
import {RoadDiaryScreen} from "../screens/RoadDiaryScreen";
import {useApiStateEffect, SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


interface RoadDetailRouterProps {
}

export const RoadDetailRouter = ({}: RoadDetailRouterProps) => {
    const {uzemiId, mistniKomunikaceId} = useUrlParams();
    const [detailApiState] = useApiStateEffect(() => getRoadDetail(mistniKomunikaceId, uzemiId));

    return <SkeletonLoader
        data={[detailApiState]}
        render={([detail]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('mistniKomunikace', {uzemiId, mistniKomunikaceId})}
                        label={`Místní komunikace ${detail.oznaceni}`}
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <RoadScreen detail={detail}/>
                        }/>

                        <Route path={RouterPaths.mistniKomunikaceHlaseni + "/*"} element={
                            <RoadDiaryScreen detail={detail}/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
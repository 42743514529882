import { BreadcrumbSetter, MapView } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";
import { useMapBridges } from "../../hooks/map/useMapBridges";
import { useMapParkings } from "../../hooks/map/useMapParkings";
import { useMapRoads } from "../../hooks/map/useMapRoads";
import Legend from "./components/Legend";

export const MapScreen = () => {
	const { state: roadState, add: addRoadsToMap } = useMapRoads();
	const { state: bridgeState, add: addBridgesToMap } = useMapBridges();
	const { state: parkingState, add: addParkingsToMap } = useMapParkings();

	return (
		<div className={"h-100"}>
			<BreadcrumbSetter to={getAbsoluteRoutePath("mapa", {})} label={`Mapa místních komunikací v obci`} />
			<div className="h-100">
				<MapView
					apiStates={[roadState, bridgeState, parkingState]}
					dataProcess={[addRoadsToMap, addBridgesToMap, addParkingsToMap]}
					usePanorama
					defaultPanoramaPhoto={"default_pano.jpg"}
				>
					{(map, layers) => (
						<>
							<Legend
								map={map}
								roadsLayer={layers.roadsLayer}
								roadsSubLayers={[layers.roadsVozovkaLayer, layers.roadsChodnikLayer, layers.roadsCyklostezkaLayer, layers.roadsChodnikCyklostezkaLayer]}
								roadsTridaLayers={[layers.roadsTrida1Layer, layers.roadsTrida2Layer, layers.roadsTrida3Layer, layers.roadsTrida4Layer]}
								bridgesLayer={layers.bridgesLayer}
								bridgesSublayers={[layers.bridgesMostLayer, layers.bridgesPropustekLayer]}
								parkingsLayer={layers.parkingsLayer}
								panoramasLayer={layers.panoramasLayer}
								showPanoramaLayer={true}
							/>
						</>
					)}
				</MapView>
			</div>
		</div>
	);
};

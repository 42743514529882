import {getAllParkingNamesInLau} from "@apis/Parking";
import {useReduxApiStateEffect} from "../redux";
//import { getAllParkingNamesInLau } from "@apis/Parking";

export const useParkingNames = () => {
    const [parkingNameListApiState, invalidateParkingNames] = useReduxApiStateEffect("parking-names", () => getAllParkingNamesInLau());/*probably add activeLau deps*/

    return {
        parkingNameListApiState,
        parkingNames: (parkingNameListApiState.data ?? []) as string[],
        invalidateParkingNames,
    }
}
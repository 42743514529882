import {RoadFilterModal} from "./RoadFilterModal";
import React, {useState} from "react";
import {RoadFilterDispatch, RoadFilterState} from "./roadFilter.utils";
import { Button, FilterIcon } from "@pasport/react-ui";

interface RoadFilterModalButtonProps {
    dispatch: RoadFilterDispatch,
    filterState: RoadFilterState
}


export const RoadFilterModalButton = ({dispatch, filterState}: RoadFilterModalButtonProps) => {
    const [filterVisible, setFilterVisible] = useState(false);

    const advancedFilterCount = [filterState.typ, filterState.trida, filterState.usek].filter(Boolean).length;

    return (
        <>
            <Button color='primary' variant='outlined' icon={<FilterIcon/>}
                    onPress={() => setFilterVisible(true)}>
                Filtry
                {advancedFilterCount > 0 && (
                    <span
                        className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                            {advancedFilterCount}
                        </span>
                )}
            </Button>
            <RoadFilterModal
                visible={filterVisible}
                onClose={() => setFilterVisible(false)}
                dispatch={dispatch}
                filterState={filterState}
            />
        </>
    );
}
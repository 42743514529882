import { createBridgeDiaryEntry, getBridgeDiaryEntryList } from "@apis/Bridge";
import { BridgeFormEditable } from "../components/Bridge";
import { BridgeDto } from "../interfaces/BridgeDto";
import { DiaryEntryCreateForm, DiaryList } from "@pasport/react-common";
import { useNavigate } from "react-router-dom";
import { useAlert, useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent } from "@pasport/react-common";
import { DetailIcon, AddIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface BridgeScreenProps {
	detail: BridgeDto;
}

export const BridgeScreen = ({ detail }: BridgeScreenProps) => {
	const { uzemiId, mostId } = useUrlParams();
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();

	const [diaryEntriesApiState, invalidateDiaryEntries] = useApiStateEffect(() => getBridgeDiaryEntryList(mostId, uzemiId));

	return (
		<Page>

			<SkeletonLoader
				data={[diaryEntriesApiState]}
				render={([diaryEntries]) => (
					<>
						<PageHeader title={`Mostní objekt ${detail.oznaceni}`} />
						<TabRoutes
							links={[{
								to: "detail",
								label: "Detail mostního objektu",
								icon: <DetailIcon />,
								content: (
									<TabContent title={"O mostním objektu"}>
										<BridgeFormEditable detail={detail} districtId={uzemiId} />
									</TabContent>
								),
							}, {
								to: "denik",
								label: "Deník mostního objektu",
								icon: <DetailIcon />,
								content: (
									<DiaryList
										items={diaryEntries}
										getLink={(diary) => getAbsoluteRoutePath("mostHlaseni", {
											uzemiId,
											mostId,
											mostHlaseniId: diary.id,
										})}

									/>
								),
							}]}
							actionLinks={[{
								to: "pridat-denik",
								label: "Přidat deníkový záznam",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat deníkový záznam"}>
										<DiaryEntryCreateForm
											dispatchCreate={(diaryEntry) => createBridgeDiaryEntry(diaryEntry, mostId, uzemiId)}
											onCreateSuccess={(diaryEntryId: number) => {
												addSuccessAlert({ message: "K mostnímu objektu bylo přidáno deníkové hlášení." });
												navigate(
													getAbsoluteRoutePath("mostHlaseni", {
														uzemiId,
														mostId,
														mostHlaseniId: diaryEntryId,
													}),
												);
												invalidateDiaryEntries();
											}}
										/>
									</TabContent>
								),
							}]}
						/>
					</>
				)}
			/>
		</Page>
	);


};
import { createRoadDiaryEntry, getRoadDiaryEntryList } from "@apis/Road";
import { RoadFormEditable } from "../components/Road/RoadFormEditable";
import { DiaryEntryCreateForm, DiaryList, DiaryEntryDto } from "@pasport/react-common";
import { RoadDto } from "../interfaces";
import { useNavigate } from "react-router-dom";
import { useAlert, useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent } from "@pasport/react-common";
import { DetailIcon, AddIcon } from "@pasport/react-ui";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { Page } from "@pasport/react-common";

interface RoadScreenProps {
	detail: RoadDto;
}

export const RoadScreen = ({ detail }: RoadScreenProps) => {
	const { uzemiId, mistniKomunikaceId } = useUrlParams();
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();

	const [diaryEntriesApiState, invalidateDiaryEntries] = useApiStateEffect(() => getRoadDiaryEntryList(mistniKomunikaceId, uzemiId));

	return (
		<Page>

			<SkeletonLoader
				data={[diaryEntriesApiState]}
				render={([diaryEntries]: [DiaryEntryDto[]]) => (
					<>
						<PageHeader title={`Místní komunikace ${detail.oznaceni}`} />
						<TabRoutes
							links={[{
								to: "detail",
								label: "Detail místní komunikace",
								icon: <DetailIcon />,
								content: (
									<TabContent title={"O místní komunikaci"}>
										<RoadFormEditable detail={detail} districtId={uzemiId} />
									</TabContent>
								),
							}, {
								to: "denik",
								label: "Deník místní komunikace",
								icon: <DetailIcon />,
								content: (
									<DiaryList
										items={diaryEntries}
										getLink={(diary) => getAbsoluteRoutePath("mistniKomunikaceHlaseni", {
											uzemiId,
											mistniKomunikaceId,
											mistniKomunikaceHlaseniId: diary.id,
										})}

									/>
								),
							}]}
							actionLinks={[{
								to: "pridat-denik",
								label: "Přidat deníkový záznam",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat deníkový záznam"}>
										<DiaryEntryCreateForm
											dispatchCreate={(diaryEntry) => createRoadDiaryEntry(diaryEntry, mistniKomunikaceId, uzemiId)}
											onCreateSuccess={(diaryEntryId: number) => {
												addSuccessAlert({ message: "K místní komunikaci bylo přidáno deníkové hlášení." });
												navigate(
													getAbsoluteRoutePath("mistniKomunikaceHlaseni", {
														uzemiId,
														mistniKomunikaceId,
														mistniKomunikaceHlaseniId: diaryEntryId,
													}),
												);
												invalidateDiaryEntries();
											}}
										/>
									</TabContent>
								),
							}]}
						/>
					</>
				)}
			/>
		</Page>
	);


};
import React, {useMemo} from "react";
import {hasLauTownDistricts, useActiveLau} from "@pasport/react-common";
import {DiaryEntryAdvancedDetailForParking} from "../../interfaces/ParkingDto";
import {
    DiaryEntryAdvancedDto,
    DiaryListAdvanced,
    FulltextSearchOption,
    withCommonDiaryOptions
} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


const getAdvancedDiaryLinkForBridge = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForParking>) => {
    return getAbsoluteRoutePath('parkovisteHlaseni', {
        uzemiId: diaryEntry.detail.uzemiId,
        parkovisteId: diaryEntry.detail.parkovisteId,
        parkovisteHlaseniId: diaryEntry.value.id
    })
}

const getAdvancedDiaryLabelForBridge = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForParking>) => {
    return (
        <>
            <strong>{diaryEntry.detail.uzemiNazev}</strong>
            {" - Parkoviště "}<strong>{diaryEntry.detail.parkovisteOznaceni}</strong>
            {"/"}<strong>{diaryEntry.detail.komunikaceOznaceni}</strong>
        </>
    )
}


interface BridgeAllDiaryListProps {
    items: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForParking>[]
}

export const ParkingAllDiaryList = ({items}: BridgeAllDiaryListProps) => {
    const {activeLau} = useActiveLau();

    const searchOptions = useMemo(() => {
        const hasDistricts = hasLauTownDistricts(activeLau)
        const detailOptions = {
            'DISTRICT': {
                option: hasDistricts ? "Městská část" : "Katastrální území",
                placeholder: hasDistricts ? "Hledat dle městské části ..." : 'Hledat dle katastrálního území ...',
                getSearchStrings: (diary) => {
                    return [diary.detail.uzemiNazev];
                }
            },
            'ROAD': {
                option: "Komunikace",
                placeholder: "Hledat dle označení komunikace ...",
                getSearchStrings: (diary) => {
                    return [diary.detail.komunikaceOznaceni || ''];
                }
            },
            'PARKING': {
                option: "Parkoviště",
                placeholder: "Hledat dle označení parkoviště ...",
                getSearchStrings: (diary) => {
                    return [diary.detail.parkovisteOznaceni || ''];
                }
            },
        } as Record<string, FulltextSearchOption<DiaryEntryAdvancedDetailForParking>>;

        return withCommonDiaryOptions(detailOptions);

    }, [activeLau])

    return (
        <DiaryListAdvanced
            items={items}
            getLink={getAdvancedDiaryLinkForBridge}
            getLabel={getAdvancedDiaryLabelForBridge}
            searchOptions={searchOptions}
        />
    )
}
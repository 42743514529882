import React, { useEffect, useMemo, useState } from "react";
import { useShowMapLabels } from "../../../hooks/map/useShowMapLabels";
import { CLOSE_ALL_POPUPS_EVENT, MapObject, Marker, LocationPoint } from "@pasport/react-common";

type MapMarkerProps = {
	color: string,
	title: string,
	icon: React.ReactNode,
	popup: React.ReactElement,
	map: MapObject,
	thresholdZoom?: number | false,
	thresholdAlternative?: React.ReactNode,
	onClick?: () => void,
	marker?: Marker
}

export default function MapMarker({
									  color,
									  title,
									  icon,
									  popup,
									  thresholdZoom = false,
									  map,
									  thresholdAlternative,
									  onClick,
									  marker,

								  }: MapMarkerProps) {

	const size = 30;
	const iconSize = 22;

	const { isOn: showTitle } = useShowMapLabels();

	const [zoomTriggered, setZoomTriggered] = useState(thresholdZoom == false);

	const [popupOpen, setPopupOpen] = useState(false);
	const [isOver, setIsOver] = useState(false);

	const focused = useMemo(() => isOver || popupOpen, [isOver, popupOpen]);

	const pan = () => {
		if (!marker) return;

		var targetLatLng: LocationPoint = marker?.location;

		var bounds = map.map.getBounds();

		// Pokud bod není v rozsahu mapy, provedeme posun mapy
		if (!bounds.contains(targetLatLng)) {
			map.map.panTo(targetLatLng, {
				duration: 1,
				animate: true,
			});
		}
	};

	const onClickHandle = () => {
		window.dispatchEvent(new Event(CLOSE_ALL_POPUPS_EVENT));
		setPopupOpen(true);
		onClick?.();

		pan();
	};

	// Zoom thresholding
	useEffect(() => {
		if (thresholdZoom === false) {
			return;
		}
		const onZoom = () => {
			const zoom = map.map.getZoom();

			setZoomTriggered(zoom >= thresholdZoom);
		};
		onZoom();

		map.map.on("zoom", onZoom);
		return () => {
			map.map.off("zoom", onZoom);
		};
	}, [thresholdZoom]);


	useEffect(() => {
		if (!marker) return;

		const onmouseover = () => {
			setIsOver(true);
		};
		const onmouseleave = () => {
			setIsOver(false);
		};

		marker.addListener("click", onClickHandle);
		marker.addListener("mouseover", onmouseover);
		marker.addListener("mouseout", onmouseleave);

		return () => {
			marker.removeListener("click", onClickHandle);
			marker.removeListener("mouseover", onmouseover);
			marker.removeListener("mouseout", onmouseleave);
		};

	}, [marker]);


	useEffect(() => {
		const close = () => {
			setPopupOpen(false);
		};
		window.addEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		return () => {
			window.removeEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		};
	}, []);


	return (
		<div style={{
			transform: "translate(-50%, -50%)",
		}}>
			{zoomTriggered && <>
				<div style={{
					filter: focused ? "brightness(1.1) drop-shadow(0px 0px 2px rgba(0,0,0,0.2))" : "brightness(1)",
					transition: "filter 0.2s ease-out, transform 0.1s ease-out",
					transform: focused ? "scale(1.1)" : "scale(1)",
					backgroundColor: color,
					borderRadius: size * 0.2,
					minWidth: size,
					padding: showTitle ? 4 : "unset",
					paddingRight: showTitle ? 8 : "unset",
					height: size,
					display: "flex",
					flexDirection: "row",
					justifyContent: showTitle ? "start" : "center",
					alignItems: "center",
					overflow: "hidden",
				}}>
					<div style={{
						width: iconSize,
						height: iconSize,
						flexShrink: 0,
					}}>
						{icon}
					</div>

					{showTitle && <div style={{
						marginLeft: 5,
					}} className="lh-1">
						{title.replace("-", "\u2011")}
					</div>}
				</div>
			</>}

			{popup && React.cloneElement(popup, { open: popupOpen })}

			{!zoomTriggered && <>
				{thresholdAlternative}
			</>}
		</div>
	);
}

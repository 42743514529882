import { BridgeDto, DiaryEntryAdvancedDetailForBridge } from "../../interfaces/BridgeDto";

import { RoadDto, TownDistrictDto } from "../../interfaces";
import { DiaryEntryAdvancedBridgeApiDto, MapBridgeApiDto } from "./BridgeApi.dto";
import { mapApiToBridgeDto, mapApiToBridgeNameDto, mapApiToMapBridgeDto, mapBridgeDtoToApi } from "./BridgeApi.map";

import {
	fetchEntityList,
	fetchEntityDetail,
	fetchCreateEntity,
	fetchUpdateEntity,
	fetchDeleteEntity,
	downloadFile,
	normalizeText,
	fetchUploadFile,
	FileDto,
	fetchDeleteFile,
	apiToNumber,
	apiToString,
	fetchToJson,
	PositionDto,
	mapPositionToRealJTSK,
	numberToApi,
	mapDiaryEntryToApi,
	mapApiToDiaryEntry,
	DiaryEntryAdvancedDto,
	DiaryEntryDto,
	mapApiToDiaryEntryAdvanced, mapApiToPhoto,
	dateToString,
} from "@pasport/react-common";
import { FileUploadDto } from "@pasport/react-common/dist/layout/FileListCard";


export const getBridgeList = (districtId: number) => {
	return fetchEntityList(`SeznamMOuzemi/${districtId}`, mapApiToBridgeDto);
};

export const getBridgeDetail = (bridgeId: number, districtId: number) => {
	return fetchEntityDetail(`DetailMO/${districtId}/${bridgeId}`, mapApiToBridgeDto);
};

export const createBridge = (bridge: BridgeDto, districtId: number) => {
	return fetchCreateEntity("insertMO", {
		...mapBridgeDtoToApi(bridge),
		uzemi_id: districtId,
	}).then(async bridgeId => {
		if (bridge.pozice) await updateBridgePosition(bridge.pozice, bridgeId, districtId);
		return bridgeId;
	});
};

export const updateBridge = (bridge: BridgeDto, districtId: number) => {
	return fetchUpdateEntity("updateMOdata", {
		...mapBridgeDtoToApi(bridge),
		mo_id: bridge.id,
		uzemi_id: districtId,
	}).then(async () => updateBridgePosition(bridge.pozice, bridge.id, districtId));
};

export const deleteBridge = (bridgeId: number, districtId: number) => {
	return fetchDeleteEntity("MOdelete", {
		"MoId": bridgeId,
		"uzemi": districtId,
	});
};

//download
export const downloadBridgeListTable = async (district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_mostni_objekty_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportMO/${district.id}`, fileName, true);
};


//photos

export const getBridgePhotoList = async (bridgeId: number, districtId: number) => {
	return fetchEntityList(`SeznamFotMO/${districtId}/${bridgeId}`, mapApiToPhoto);
};

export const uploadBridgePhoto = async (file: FileUploadDto, bridgeId: number, districtId: number) => {
	return fetchUploadFile(file, {
		case: "MO",
		MoId: bridgeId,
	});
};

export const deleteBridgePhoto = async (file: FileDto, bridgeId: number, districtId: number) => {
	return fetchDeleteFile("deleteFileMO", file, {
		MoId: bridgeId,
		uzemi: districtId,
	});
};


//diaries
export const getBridgeDiaryEntryList = (bridgeId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniMO/${districtId}/${bridgeId}`, mapApiToDiaryEntry);
};


export const createBridgeDiaryEntry = async (diaryEntry: DiaryEntryDto, bridgeId: number, districtId: number) => {
	return fetchCreateEntity("insertHlaseniMO", {
		...mapDiaryEntryToApi(diaryEntry, true),
		mo_id: bridgeId,
		IXopraveno: "0",
	});
};

export const getBridgeDiaryEntryDetail = async (diaryEntryId: number, bridgeId: number, districtId: number) => {
	return fetchEntityDetail(`DetailHlaseniMO/${districtId}/${bridgeId}/${diaryEntryId}`, mapApiToDiaryEntry);
};

export const updateBridgeDiaryEntry = async (diaryEntry: DiaryEntryDto, bridgeId: number, districtId: number) => {
	return fetchUpdateEntity("updateHlaseniMO", {
		...mapDiaryEntryToApi(diaryEntry, false),
		hlaseni_id: diaryEntry.id,
		mo_id: bridgeId,
		uzemi_id: districtId,
	});
};

export const deleteBridgeDiaryEntry = async (diaryEntryId: number, bridgeId: number, districtId: number) => {
	return fetchDeleteEntity("deleteHlaseniMO", {
		hlaseniId: diaryEntryId,
		mo_id: bridgeId,
		uzemi: districtId,
	});
};

export const getBridgeDiaryEntryRelatedList = (parentDiaryId: number, bridgeId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniMOdt/${districtId}/${bridgeId}/${parentDiaryId}`, mapApiToDiaryEntry);
};


//diary photos

export const getBridgeDiaryPhotoList = async (diaryEntryId: number, bridgeId: number, districtId: number) => {
	return fetchEntityList(`SeznamFotHlasMO/${districtId}/${bridgeId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadBridgeDiaryPhoto = async (file: FileUploadDto, diaryEntryId: number) => {
	// TODO: FIXME: this is not working
	return fetchUploadFile(file, {
		case: "HlaseniMO",
		hlaseni_id: diaryEntryId,
	});
};

export const deleteBridgeDiaryPhoto = async (file: FileDto, diaryEntryId: number, bridgeId: number, districtId: number) => {
	return fetchDeleteFile("deleteFileHlaseniMO", file, {
		hlaseni_id: diaryEntryId,
		mo_id: bridgeId,
		uzemi: districtId,
	});
};


//all diaries
export const getAllBridgeDiaryEntryList = async (): Promise<DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForBridge>[]> => {
	return fetchEntityList(`SeznamHlaseniMOlau`, (response: DiaryEntryAdvancedBridgeApiDto) => mapApiToDiaryEntryAdvanced(response,
		(data) => ({
			uzemiId: apiToNumber(data.uzemi_id),
			uzemiNazev: apiToString(data.nazev_ku),
			mostId: apiToNumber(data.mo_id),
			mostOznaceni: apiToString(data.oznaceni_mo),
			komunikaceOznaceni: apiToString(data.oznaceni_mk),
		}),
	));
};


//map
export const getMapBridgeList = async (districtId: number) => {
	const list: MapBridgeApiDto[] = await fetchToJson(`MapaMO`)
		.then((r) => {
			return r.data;
		});
	const result = list.map((item) => mapApiToMapBridgeDto(item));
	return result;
};

//update position
export const updateBridgePosition = (position: PositionDto | undefined, bridgeId: number, districtId: number) => {
	const pos = position?.x && position?.y ? mapPositionToRealJTSK(position.x, position.y) : { x: null, y: null };
	return fetchUpdateEntity("updateSPIMO", {
		st_x: numberToApi(pos.x),
		st_y: numberToApi(pos.y),
		MoId: bridgeId,
		//uzemi: districtId
	});
};


//helpers
export const getAllBridgeNamesInLau = async (): Promise<string[]> => {
	return fetchEntityList(`MOSeznamMO`, mapApiToBridgeNameDto);
};

export const getBridgeListForRoad = async (districtId: number, road: RoadDto): Promise<BridgeDto[]> => {
	return fetchEntityList(`SeznamMO/${road.id}`, mapApiToBridgeDto);
};
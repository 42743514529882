import {RoadDto} from "../../../interfaces";
import {useEffect, useReducer} from "react";
import {ROAD_SORT_OPTIONS, ROAD_SORT_OPTIONS_KEYS, RoadFilterActionTypes, roadFilterReducer} from "./roadFilter.utils";
import {SORT_DIRECTION, getFulltextSearch, sortBySortMethod, useUrlState} from "@pasport/react-common";


function getSortingFunc(key: ROAD_SORT_OPTIONS_KEYS, sortDirection: SORT_DIRECTION): (a: RoadDto, b: RoadDto) => number {
    const increasingOrder = sortDirection === SORT_DIRECTION.UP;
    const func = ROAD_SORT_OPTIONS[key].method;
    return (a, b) => func(a, b, increasingOrder)
}


export const useRoadFilter = (objects: RoadDto[]) => {
    const [searchState, setSearchState] = useUrlState("search");
    const [state, dispatch] = useReducer(roadFilterReducer, {
        sortDirection: SORT_DIRECTION.UP,
        sortMethod: "OZNACENI",
        searchQuery: searchState
    });

    useEffect(() => {
        setSearchState(state.searchQuery);
    }, [state.searchQuery])

    const itemsFulltext = getFulltextSearch(state.searchQuery ?? '', objects, item => [
        item.oznaceni,
        item.nazev
    ]);
    const filteredItemsA = sortBySortMethod(itemsFulltext, getSortingFunc(state.sortMethod, state.sortDirection))
    const filteredItems = filteredItemsA.filter(item => (
        (!state.typ || item.typ === state.typ) &&
        (!state.trida || item.trida === state.trida) &&
        (!state.usek || item.usek === state.usek)
    ));


    return {
        setSearchQuery: (query: string) => dispatch({type: RoadFilterActionTypes.SET_SEARCH, payload: query}),
        setSortMethod: (sortMethod: ROAD_SORT_OPTIONS_KEYS) => dispatch({
            type: RoadFilterActionTypes.SET_SORT_METHOD,
            payload: sortMethod
        }),
        setSortDirection: (sortDirection: SORT_DIRECTION) => dispatch({
            type: RoadFilterActionTypes.SET_SORT_DIRECTION,
            payload: sortDirection
        }),
        dispatch,
        filterState: state,
        filteredItems
    }
}
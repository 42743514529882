import { formatDate } from "@pasport/react-common";
import { ListItem, Row, RowItem } from "@pasport/react-ui";
import {BridgeDto} from "../../interfaces/BridgeDto";
import {BridgeIcon} from "./BridgeIcon";

interface BridgeItemProps {
    value: BridgeDto,
    link: string
}


export const BridgeItem = ({value, link}: BridgeItemProps) => {
    const hasTyp = Boolean(value.typ) && value.typ !== "-";
    return (
        <ListItem link={link} icon={<BridgeIcon className='fs-3'/>}>
            <Row>
                <RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
                <RowItem label={"Název"} size={2}>{value.nazev}</RowItem>
                <RowItem label={"Komunikace"} size={1.5}>{value.komunikace}</RowItem>
                <RowItem label={"Druh"}
                         size={3}>{hasTyp ? `${value.druh} / ${value.typ}` : `${value.druh}`}</RowItem>
                <RowItem label={"Poslední závada"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniZavady)}</RowItem>
                <RowItem label={"Poslední údržba"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
                <RowItem label={"Poznámka"} size={1.5} priority={9}>{value.poznamka}</RowItem>
                {/*FIXME its not 12 size*/}
            </Row>
        </ListItem>
    );
}
import { TownDistrictDto } from "../interfaces";
import { downloadParkingListTable, getParkingList } from "@apis/Parking";
import { ParkingList } from "../components/Parking";
import { ParkingFormCreate } from "../components/Parking/ParkingFormCreate";
import { DownloadTableTile } from "../common/DownloadTableTile";
import { useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent } from "@pasport/react-common";
import { ListIcon, AddIcon, DownloadIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface ParkingOverviewScreenProps {
	detail: TownDistrictDto;
}

export const ParkingOverviewScreen = ({ detail }: ParkingOverviewScreenProps) => {
	const { uzemiId } = useUrlParams();

	const [parkingListApiState, invalidateParkingList] = useApiStateEffect(() => getParkingList(uzemiId));

	return (

		<SkeletonLoader
			data={[parkingListApiState]}

			render={([parkingList]) => (
				<Page>
					<PageHeader title={`Parkoviště - ${detail.nazev}`} />
					<TabRoutes
						links={[{
							to: "seznam",
							label: "Seznam parkovišť",
							icon: <ListIcon />,
							content: (
								<TabContent
									title={"Seznam parkovišť"}
									isEmpty={parkingList.length === 0}
									emptyLabel={"Zatím zde není žádné parkoviště"}
								>
									<ParkingList
										items={parkingList}
										getLink={parking => getAbsoluteRoutePath("parkoviste", {
											uzemiId,
											parkovisteId: parking.id,
										})}
									/>
								</TabContent>
							),
						}]}

						actionLinks={[
							{
								to: "pridat-parkoviste",
								label: "Přidat parkoviště",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat parkoviště"}>
										<ParkingFormCreate onCreate={invalidateParkingList} districtId={uzemiId} />
									</TabContent>
								),

							}, {
								to: "exportovat",
								label: "Exportovat",
								icon: <DownloadIcon />,
								content: (
									<DownloadTableTile
										onDownload={() => downloadParkingListTable(detail)}
										cardTitle={"Seznam parkovišť"}
										buttonLabel={"Stáhnout tabulku parkovišť"}
									/>
								),
							}]}
					/>
				</Page>
			)}
		/>

	);
};
import {BridgeDruh, BridgeDto, BridgeTyp, MapBridgeDto} from "../../interfaces/BridgeDto"
import {BridgeApiInDto, BridgeApiOutDto, BridgeNameApiDto, MapBridgeApiDto} from "./BridgeApi.dto"
import {BridgePrekazka, BridgeStav} from "../../consts/Bridge";
import {
    convertInnerLocationToGPS,
    apiToFloatNumber,
    apiToNumber,
    apiToString,
    apiToPosition,
    apiToDate,
    stringToApi,
    numberToApi,
    dateToApi, apiToLocation
} from "@pasport/react-common";

const apiToBridgeDruh = (api: string): BridgeDruh => api as BridgeDruh;
const apiToBridgeTyp = (api: string): BridgeTyp => api as BridgeTyp;

export const mapApiToBridgeDto = (api: BridgeApiInDto): BridgeDto => {
    return {
        id: apiToNumber(api.mo_id),
        oznaceni: apiToString(api.oznaceni),
        nazev: apiToString(api.nazev),
        druh: apiToBridgeDruh(apiToString(api.druh_mo)),
        typ: apiToBridgeTyp(apiToString(api.typ_mostu)),
        komunikace: apiToString(api.komunikace),
        konstrukce: apiToString(api.konstrukce),
        vlastnik: apiToString(api.vlastnik),
        prekazka: apiToString(api.prekazka) as BridgePrekazka,
        stav: apiToString(api.stav) as BridgeStav,
        location: apiToLocation(api.st_x, api.st_y),

        pozice: apiToPosition(api.st_x, api.st_y),
        sirka: apiToFloatNumber(api.sirka),
        vyska: apiToFloatNumber(api.vyska),
        datumVystavby: apiToDate(api.datum_vystavby),
        datumMapovani: apiToDate(api.datum_mapovani),
        datumAktualizace: apiToDate(api.datum_aktualizace),
        datumPosledniZavady: apiToDate(api.datum_posl_zavady),
        datumPosledniUdrzby: apiToDate(api.datum_posl_servisu),
        poznamka: apiToString(api.poznamka),
        dalsiInfo: apiToString(api.dalsi_info),
        uzemiId: apiToNumber(api.uzemi_id) || apiToNumber(api.uzemi_id_mo) || undefined,
    }
}

const getBridgeTyp = (dto: BridgeDto): BridgeTyp => {
    if (dto.druh === BridgeDruh.MOST) {
        return dto.typ;
    } else {
        return BridgeTyp.PROPUSTEK;
    }
}

export const mapBridgeDtoToApi = (dto: BridgeDto): BridgeApiOutDto => {
    return {
        oznaceni: stringToApi(dto.oznaceni),
        nazev: stringToApi(dto.nazev),
        druh_mo: stringToApi(dto.druh),
        typ_mostu: stringToApi(getBridgeTyp(dto)),
        komunikace: stringToApi(dto.komunikace),
        vlastnik: stringToApi(dto.vlastnik),
        konstrukce: stringToApi(dto.konstrukce),
        prekazka: stringToApi(dto.prekazka),
        stav: stringToApi(dto.stav),
        IXsirka: numberToApi(dto.sirka),
        IXvyska: numberToApi(dto.vyska),
        DXdatum_vystavby: dateToApi(dto.datumVystavby),
        DXdatum_mapovani: dateToApi(dto.datumMapovani),
        DXdatum_aktualizace: dateToApi(dto.datumAktualizace),
        dalsi_info: stringToApi(dto.dalsiInfo),
        poznamka: stringToApi(dto.poznamka)

    }
}

export const mapApiToMapBridgeDto = (api: MapBridgeApiDto): MapBridgeDto => {
    return {
        id: api.mo_id,
        oznaceni: api.oznaceni,
        nazev: api.nazev,
        komunikace: api.komunikace,
        uzemiId: api.uzemi_id,
        uzemiNazev: api.ku,
        location: convertInnerLocationToGPS([parseFloat(api.st_x), parseFloat(api.st_y)]),
        druh: apiToBridgeDruh(api.druh_mo),
        typ: apiToBridgeTyp(api.typ_mostu),
    }
}


export const mapApiToBridgeNameDto = (response: BridgeNameApiDto): string => {
    return response.oznaceni;
}
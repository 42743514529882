import React from "react";
import { useShowMapLabels } from "../../../hooks/map/useShowMapLabels";
import { Checkbox } from "@pasport/react-ui";

export default function ShowTitlesButton() {
	const { turnOn, turnOff } = useShowMapLabels();
	const [showOznaceni, setShowOznaceni] = React.useState(false);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "start",
			}}
		>
			<Checkbox
				variant="switch"
				label={"Zobrazit označení"}
				onChange={(active) => {
					setShowOznaceni(active);
					if (active) {
						turnOn();
					} else {
						turnOff();
					}
				}}
				labelPosition="end"
			/>
		</div>
	);
}

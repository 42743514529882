import {LocationPoint} from "maps-api/lib/core/LocationPoint"; //FIXME

export enum ParkingParkovani {
    PRICNE = "příčné",
    PODILNE = "podélné",
    KOMBINOVANE = "kombinované",
    NEZJISTENO = "nezjištěno"
}

export enum ParkingPovrch {
    ASFALT = "asfalt (živice)",
    BETON = "beton",
    DLAZBA = "dlažba",
    RECYKLAZ = "recykláž (R-materiál)",
    PISEK = "písek, štěrkopísek",
    SOTOLINA = "šotolina (štěrk)",
    NEZPEVNENO = "nezpevněno",
    JINY = "jiný",
    NEZJISTENO = "nezjištěno"
}


export interface ParkingDto {
    id: number,
    oznaceni: string,
    nazev: string,
    komunikace: string,
    parkovani: ParkingParkovani,
    vlastnik: string,
    povrch: ParkingPovrch,
    geometry: LocationPoint[][] | null,
    geometryString: string,
    stav: string,
    plocha: number,
    pocetMist: number,
    pocetMistProInvalidy: number,
    pocetVyhrazenychMist: number,
    datumVystavby: Date | null,
    datumMapovani: Date | null,
    datumAktualizace: Date | null,
    datumPosledniZavady: Date | null,
    datumPosledniUdrzby: Date | null,
    poznamka: string,
    dalsiInfo: string
    uzemiId?: number,
}

export type MapParkingDto = {
    id: number,
    oznaceni: string,
    nazev: string,
    komunikace: string,
    parkovani: ParkingParkovani,
    uzemiId: number,
    uzemiNazev: string,
    geometry: LocationPoint[][],
}


export type DiaryEntryAdvancedDetailForParking = {
    uzemiId: number,
    uzemiNazev: string,
    parkovisteId: number,
    parkovisteOznaceni: string,
    komunikaceOznaceni: string,

}
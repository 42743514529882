import {Route, Routes} from "react-router-dom";
import React from "react";
import {ParkingScreen} from "../screens/ParkingScreen";
import {getParkingDetail} from "@apis/Parking";
import {ParkingDiaryScreen} from "../screens/ParkingDiaryScreen";
import {useApiStateEffect, SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


interface ParkingDetailRouterProps {
}

export const ParkingDetailRouter = ({}: ParkingDetailRouterProps) => {
    const {uzemiId, parkovisteId} = useUrlParams()
    const [detailApiState] = useApiStateEffect(() => getParkingDetail(parkovisteId, uzemiId));

    return <SkeletonLoader
        data={[detailApiState]}
        render={([detail]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('parkoviste', {uzemiId, parkovisteId})}
                        label={`Parkoviště ${detail.oznaceni}`}
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <ParkingScreen detail={detail}/>
                        }/>
                        <Route path={RouterPaths.parkovisteHlaseni + "/*"} element={
                            <ParkingDiaryScreen detail={detail}/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
import {Route, Routes} from "react-router-dom";
import React from "react";
import {useTownDistrict} from "../components/TownDistrict/useTownDistrict";
import {TownDistrictDto} from "../interfaces";
import {BridgeOverviewScreen} from "../screens/BridgeOverviewScreen";
import {BridgeDetailRouter} from "./BridgeDetailRouter";
import {SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


interface BridgeRouterProps {
    detail: TownDistrictDto
}

export const BridgeRouter = ({detail}: BridgeRouterProps) => {
    const {uzemiId} = useUrlParams()
    const {getTownDistrictDetail} = useTownDistrict();
    return <SkeletonLoader
        data={[getTownDistrictDetail(uzemiId)]}
        render={([districtDetail]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('prehledMostu', {uzemiId})}
                        label={`Mostní objekty`}
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <BridgeOverviewScreen detail={districtDetail}/>
                        }/>
                        <Route path={RouterPaths.most + "/*"} element={
                            <BridgeDetailRouter/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
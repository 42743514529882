import {useNavigate} from "react-router-dom";
import {ParkingCards} from "./ParkingCards";
import {ParkingDto, ParkingParkovani, ParkingPovrch} from "../../interfaces/ParkingDto";
import {createParking} from "@apis/Parking";
import {useParkingNames} from "../../hooks/useParkingNames";
import {useAlert, FormWithCreate, FormMode, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


const defaultParking: ParkingDto = {
    id: 0,
    oznaceni: "",
    nazev: "",
    komunikace: "",
    geometry: [],
    geometryString: "",
    parkovani: ParkingParkovani.NEZJISTENO,
    vlastnik: "",
    povrch: ParkingPovrch.ASFALT,
    stav: "",
    plocha: 0,
    pocetMist: 0,
    pocetMistProInvalidy: 0,
    pocetVyhrazenychMist: 0,
    datumVystavby: null,
    datumMapovani: null,
    datumAktualizace: null,
    datumPosledniZavady: null,
    datumPosledniUdrzby: null,
    poznamka: "",
    dalsiInfo: "",
}


interface ParkingFormCreateProps {
    districtId: number,
    onCreate?: () => void,
}

export const ParkingFormCreate = ({districtId, onCreate}: ParkingFormCreateProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();
    const {invalidateParkingNames} = useParkingNames();


    return (
        <FormWithCreate
            initValues={defaultParking}
            dispatchCreate={(value) => createParking(value, districtId)}
            onCreateSuccess={(parkingId: number) => {
                addSuccessAlert({message: "Bylo vytvořeno parkoviště."});
                navigate(
                    getAbsoluteRoutePath('parkoviste', {
                        uzemiId: districtId,
                        parkovisteId: parkingId
                    })
                );
                if (onCreate) onCreate();
                invalidateParkingNames();
            }}
        >
            {() => <ParkingCards mode={FormMode.EDIT} version={FormVersion.CREATE}/>}
        </FormWithCreate>
    )

}


import React, {useEffect, useState} from "react";
import {RoadSearchResultDto} from "../../interfaces";
import {useNavigate} from "react-router-dom";
import {getRoadListByQuery} from "@apis/Road";
import {useApiState, SkeletonLoader, TabEmptyContent} from "@pasport/react-common";
import {Modal, SearchInput, Card} from "@pasport/react-ui";
import {getAbsoluteRoutePath} from "../../core/routing";

interface RoadSearchModalProps {
    visible: boolean,
    onClose: () => void,
}

export const RoadSearchModal = ({visible, onClose}: RoadSearchModalProps) => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState("");

    const {fetchApiState, apiState} = useApiState<RoadSearchResultDto[]>();

    useEffect(() => {
        setSearchQuery('')
    }, [visible])


    useEffect(() => {
        if (searchQuery !== '') {
            fetchApiState(() => getRoadListByQuery(searchQuery));
        } else {
            fetchApiState(() => new Promise((resolve) => resolve([])));
        }
    }, [searchQuery]);

    return (
        <Modal show={visible} onClose={onClose} colorVariant='light' align={'top'}
               header={(
                   <>
                       <div className="fs-5 fw-bold pb-2">Vyhledání místní komunikace</div>
                       <SearchInput placeholder={"Zadejte označení místní komunikace"}
                                    onTextChange={(text) => setSearchQuery(text.trim())} autoFocus size='large'/>
                   </>
               )}
        >

            <div className="overflsow-auto">
                <SkeletonLoader
                    data={[apiState]}
                    render={([roadList]) => (
                        <div className={"d-flex flex-column gap-3"}>
                            {roadList.length > 0 ? (
                                <div>
                                    <div className="fs-6 p-1 pt-0">Nalezené místní komunikace ({roadList.length})</div>
                                    <div className={"d-flex flex-column gap-2"}>
                                        {roadList.map(item => (
                                            <Card flat
                                                  onPress={() => navigate(getAbsoluteRoutePath('mistniKomunikace', {
                                                      uzemiId: item.uzemiId,
                                                      mistniKomunikaceId: item.id
                                                  }))}
                                            >
                                                <div
                                                    className={"p-3 fs-6 d-flex align-items-center justify-content-between "}>
                                                    <div>
                                                        <i className="bi bi-arrow-return-right pe-4"></i>
                                                        <strong>{item.uzemiNazev}</strong>
                                                        <i className="bi bi-chevron-right px-2 "></i>
                                                        <strong>{item.oznaceni}</strong>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                    </div>


                                </div>) : (
                                <TabEmptyContent
                                    text={searchQuery === '' ? 'Zadejte přesné označení místní komunikace.' : (
                                        <div>
                                            Výrazu "<strong>{searchQuery}</strong>" neodpovídá žádná místní komunikace.
                                        </div>
                                    )}
                                />
                            )}

                        </div>
                    )}
                    renderLoading={() => <TabEmptyContent text={"..."}/>}
                />

            </div>

        </Modal>
    )
}
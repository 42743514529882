import { TownDistrictDto } from "../interfaces";
import { downloadBridgeListTable, getBridgeList } from "@apis/Bridge";
import { BridgeList } from "../components/Bridge";
import { BridgeFormCreate } from "../components/Bridge/BridgeFormCreate";
import { DownloadTableTile } from "../common/DownloadTableTile";
import { useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent } from "@pasport/react-common";
import { ListIcon, AddIcon, DownloadIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface BridgeOverviewScreenProps {
	detail: TownDistrictDto;
}

export const BridgeOverviewScreen = ({ detail }: BridgeOverviewScreenProps) => {
	const { uzemiId } = useUrlParams();

	const [bridgeListApiState, invalidateBridgeList] = useApiStateEffect(() => getBridgeList(uzemiId));

	return (

		<SkeletonLoader
			data={[bridgeListApiState]}
			render={([bridgeList]) => (
				<Page>
					<PageHeader title={`Mostní objekty - ${detail.nazev}`} />
					<TabRoutes
						links={[{
							to: "seznam",
							label: "Seznam mostních objektů",
							icon: <ListIcon />,
							content: (
								<TabContent
									title={"Seznam mostních objektů"}
									isEmpty={bridgeList.length === 0}
									emptyLabel={"Zatím zde není žádný mostní objekt"}
								>
									<BridgeList
										items={bridgeList}
										getLink={bridge => getAbsoluteRoutePath("most", {
											uzemiId,
											mostId: bridge.id,
										})}
									/>
								</TabContent>
							),
						}]}

						actionLinks={[
							{
								to: "pridat-most",
								label: "Přidat mostní objekt",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat mostní objekt"}>
										<BridgeFormCreate districtId={uzemiId} onCreate={invalidateBridgeList} />
									</TabContent>
								),

							}, {
								to: "exportovat",
								label: "Exportovat",
								icon: <DownloadIcon />,
								content: (
									<DownloadTableTile
										onDownload={() => downloadBridgeListTable(detail)}
										cardTitle={"Seznam mostních objektů"}
										buttonLabel={"Stáhnout tabulku mostních objektů"}
									/>
								),
							}]}
					/>
				</Page>
			)}
		/>

	);
};
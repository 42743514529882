import {useNavigate} from "react-router-dom";
import {ParkingDto} from "../../interfaces/ParkingDto";
import {
    deleteParking,
    deleteParkingPhoto,
    getParkingPhotoList,
    updateParking,
    uploadParkingPhoto
} from "@apis/Parking";
import {ParkingCards} from "./ParkingCards";
import {useParkingNames} from "../../hooks/useParkingNames";
import {getRoadListByQuery} from "@apis/Road";
import {useAlert, FormWithUpdate, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


interface ParkingEditableDetailProps {
    detail: ParkingDto,
    districtId: number
}

export const ParkingFormEditable = ({detail, districtId}: ParkingEditableDetailProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert, addErrorAlert} = useAlert();
    const {invalidateParkingNames} = useParkingNames();

    const navigateToRoadDetail = async (query: string) => {
        const roadList = await getRoadListByQuery(query);
        const road = roadList?.[0];
        if (road) {
            navigate(getAbsoluteRoutePath('mistniKomunikace', {uzemiId: road.uzemiId, mistniKomunikaceId: road.id}));
        } else {
            addErrorAlert({message: 'Omlouváme se, ale komunikace nebyla nalezena.'});
        }
    }

    return (
        <FormWithUpdate
            initValues={detail}
            dispatchUpdate={(updated) => updateParking(updated, districtId)}
            dispatchDelete={() => deleteParking(detail.id, districtId)}
            onUpdateSuccess={() => {
                addSuccessAlert({message: 'Parkoviště bylo upraveno'});
                invalidateParkingNames();
            }}
            onDeleteSuccess={() => {
                addSuccessAlert({message: 'Parkoviště bylo odstraněno'});
                navigate(getAbsoluteRoutePath('prehledParkovist', {uzemiId: districtId}));
                invalidateParkingNames();
            }}
        >
            {({mode}) => (
                <ParkingCards
                    mode={mode} version={FormVersion.UPDATE}
                    photoApi={{
                        getList: () => getParkingPhotoList(detail.id, districtId),
                        upload: (file) => uploadParkingPhoto(file, detail.id, districtId),
                        delete: (file) => deleteParkingPhoto(file, detail.id, districtId)
                    }}
                    previousParkingName={detail.oznaceni}
                    onRoadDetailPress={detail.komunikace ? () => navigateToRoadDetail(detail.komunikace) : undefined}
                />
            )}
        </FormWithUpdate>
    )
}
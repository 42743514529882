import { setPasportConfig } from "@pasport/react-common";

export const setAppConfig = () => {
	setPasportConfig({
		PASPORT_ID: "mk",

		AUTH_CLIENT_ID: "pasport-mk",
		AUTH_ENDPOINT: process.env.REACT_APP_AUTHORIZATION_ENDPOINT || "",

		PASPORT_API_BASE_PATH: process.env.REACT_APP_PASPORT_API_BASE_PATH || "",
		COMMON_API_BASE_PATH: process.env.REACT_APP_COMMON_API_BASE_PATH || "",
	});
};
import { SortingFieldOptions, sortMethodString, sortMethodDate, mapSortFieldOptionsToSelectOptions } from "@pasport/react-common";
import {BridgeDto} from "../../../interfaces/BridgeDto";

export type BRIDGE_SORT_OPTIONS_KEYS =
    'OZNACENI'
    | 'NAZEV'
    | 'KOMUNIKACE'
    | 'DATUM_POSLEDNI_ZAVADY'
    | 'DATUM_POSLEDNI_UDRZBY';

export const BRIDGE_SORT_OPTIONS: SortingFieldOptions<BRIDGE_SORT_OPTIONS_KEYS, BridgeDto> = {
    OZNACENI: {
        label: "Označení",
        method: sortMethodString(obj => obj.oznaceni)
    },
    NAZEV: {
        label: "Název",
        method: sortMethodString(obj => obj.nazev)
    },
    KOMUNIKACE: {
        label: "Komunikace",
        method: sortMethodString(obj => obj.komunikace)
    },
    DATUM_POSLEDNI_ZAVADY: {
        label: "Datum poslední závady",
        method: sortMethodDate(road => road.datumPosledniZavady)
    },
    DATUM_POSLEDNI_UDRZBY: {
        label: "Datum poslední údržby",
        method: sortMethodDate(road => road.datumPosledniUdrzby)
    },
};

export const BRIDGE_SORT_OPTIONS_SELECT = mapSortFieldOptionsToSelectOptions(BRIDGE_SORT_OPTIONS);
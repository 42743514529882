import {MapParkingDto, ParkingDto} from "../../../interfaces/ParkingDto";
import {Marker} from "maps-api";
import {MarkerPopup} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../../core/routing";

interface ParkingMarkerPopupProps {
    open?: boolean;
    data: MapParkingDto;
    marker: Marker;
}

// Označení
// 			Komunikace
// 			Parkování
export default function ParkingMarkerPopup({open = false, data, marker}: ParkingMarkerPopupProps) {
    const detailUrl = getAbsoluteRoutePath("parkoviste", {
        parkovisteId: data.id,
        uzemiId: data.uzemiId,
    });
    return !open ? (
        <></>
    ) : (
        <div>
            <MarkerPopup
                subtitle="Parkoviště"
                title={data.oznaceni}
                items={[
                    {label: "Komunikace", value: data.komunikace},
                    {label: "Parkování", value: data.parkovani},
                ]}
                detailUrl={detailUrl}
                marker={marker}
            />
        </div>
    );
}

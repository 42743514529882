import {MapRoadDto, RoadDto} from "../../../interfaces";
import {Marker} from "maps-api";
import {MarkerPopup} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../../core/routing";

interface RoadMarkerPopupProps {
    open?: boolean;
    data: MapRoadDto;
    marker: Marker;
}

// Typ
// 			Třída
// 			Úsek
export default function RoadMarkerPopup({open = false, data, marker}: RoadMarkerPopupProps) {
    const detailUrl = getAbsoluteRoutePath("mistniKomunikace", {
        mistniKomunikaceId: data.id,
        uzemiId: data.uzemiId,
    });

    return !open ? (
        <></>
    ) : (
        <div>
            <MarkerPopup
                subtitle="Místní komunikace"
                title={data.oznaceni}
                items={[
                    {label: "Typ", value: data.typ},
                    {label: "Třída", value: data.trida},
                    {label: "Úsek", value: data.usek},
                ]}
                detailUrl={detailUrl}
                marker={marker}
            />
        </div>
    );
}

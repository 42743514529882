import {useState} from "react";
import {ParkingDto} from "../../../interfaces/ParkingDto";
import {PARKING_SORT_OPTIONS, PARKING_SORT_OPTIONS_KEYS} from "./parkingFilter.utils";
import {getFulltextSearch, SORT_DIRECTION, sortBySortMethod, useUrlState} from "@pasport/react-common";


function getSortingFunc(key: PARKING_SORT_OPTIONS_KEYS, sortDirection: SORT_DIRECTION): (a: ParkingDto, b: ParkingDto) => number {
    const increasingOrder = sortDirection === SORT_DIRECTION.UP;
    const realKey = key in PARKING_SORT_OPTIONS ? key : 'OZNACENI';
    const func = PARKING_SORT_OPTIONS[realKey].method;
    return (a, b) => func(a, b, increasingOrder)
}


export const useParkingFilter = (objects: ParkingDto[]) => {
    const [searchQuery, setSearchQuery] = useUrlState("search");
    const [sortMethod, setSortMethod] = useState<PARKING_SORT_OPTIONS_KEYS>('OZNACENI');
    const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);

    const itemsFulltext = getFulltextSearch(searchQuery, objects, item => [
        item.oznaceni,
        item.nazev,
        item.komunikace
    ]);
    const filteredItems = sortBySortMethod(itemsFulltext, getSortingFunc(sortMethod, sortDirection))
    return {
        setSearchQuery,
        setSortMethod,
        setSortDirection,
        filteredItems,
        searchQuery
    }
}
import { getMapParkingList } from "@apis/Parking";
import ParkingMarker from "../../components/map/markers/ParkingMarker";
import { MapParkingDto } from "../../interfaces/ParkingDto";
import { MapObject, useApiStateEffect } from "@pasport/react-common";

export const getParkingColor = (parking: MapParkingDto) => {
	return "cyan";
};

type StateType = {
	parkings: MapParkingDto[];
};

export function useMapParkings() {
	const [state] = useApiStateEffect<StateType>(async () => {
		return {
			parkings: await getMapParkingList(0),
		};
	});

	const addToMap = (map: MapObject, data: StateType) => {
		const layers = {
			parkingsLayer: map.createLayer(),
		};
		const objects = data.parkings.map((parking) => {
			const marker = map
				.createGeometryMarker(parking.geometry, "polygon", (marker) => {
					return <ParkingMarker parking={parking} marker={marker} map={map} />;
				})
				.initialize();
			marker.setStyle({
				fillColor: getParkingColor(parking),
				fillOpacity: 0.5,
				color: "cyan",
				weight: 2,
			});
			marker.addToLayer(layers.parkingsLayer);
			return marker;
		});

		layers.parkingsLayer.initialize();
		return layers;
	};

	return {
		state: state,
		add: addToMap,
	};
}

import { ApiState } from "@pasport/react-common";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ApiCallsState {
    calls: Record<string, ApiState<unknown>>
}


const initialState: ApiCallsState = {
    calls: {},
};

const apiCallsSlice = createSlice({
    name: 'apiCalls',
    initialState,
    reducers: {
        setApiState: (state, action: PayloadAction<{ key: string, apiState: ApiState<unknown> }>) => {
            state.calls = {...state.calls, [action.payload.key]: action.payload.apiState};
        },
    },

})

export const {setApiState} = apiCallsSlice.actions;
export const apiCallsReducer = apiCallsSlice.reducer;
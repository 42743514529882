import React, { useState } from "react";
import { TownDistrictDto } from "../interfaces";
import { useTownDistrict } from "../components/TownDistrict/useTownDistrict";
import { TownDistrictList } from "../components/TownDistrict/TownDistrictList";
import { hasLauTownDistricts, useActiveLau } from "@pasport/react-common";
import { RoadSearchModal } from "../components/Road/RoadSearchModal";
import { SkeletonLoader, PageHeader, TabButton } from "@pasport/react-common";
import { MapIcon, DiaryIcon, SearchIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../core/routing";


export const HomeScreen = () => {

	const { townDistrictListApiState } = useTownDistrict();
	const { activeLau } = useActiveLau();

	const [visibleSearchModal, setVisibleSearchModal] = useState(false);


	return (
		<SkeletonLoader
			data={[townDistrictListApiState]}
			render={([townDistricts]: [TownDistrictDto[]]) => (
				<Page>
					<div className={"container-sm px-5"}>
						<div className="d-flex justify-content-between align-items-start">
							<PageHeader title={
								hasLauTownDistricts(activeLau) ?
									"Vyberte městskou část" :
									"Vyberte katastrální území"
							} />

							<div className="">
								<TabButton label="Zobrazit mapu obce" to={getAbsoluteRoutePath("mapa", {})}
										   target={"_blank"} icon={<MapIcon />} />
								<TabButton label="Poslední hlášení" to={getAbsoluteRoutePath("vsechnaHlaseni", {})}
										   icon={<DiaryIcon />} />
								<TabButton label={"Vyhledat místní komunikaci"}
										   icon={<SearchIcon />}
										   onPress={() => setVisibleSearchModal(true)}
								/>
							</div>
							<RoadSearchModal visible={visibleSearchModal}
											 onClose={() => setVisibleSearchModal(false)} />
						</div>
						<TownDistrictList items={townDistricts} />
					</div>

				</Page>
			)}
		/>

	);
};

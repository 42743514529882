import React, { useEffect, useMemo, useState } from "react";
import { BridgeDto, MapBridgeDto } from "../../../interfaces/BridgeDto";
import BridgeMarkerPopup from "../popups/BridgeMarkerPopup";
import { getBridgeColor } from "../../../hooks/map/useMapBridges";
import { BridgeIcon } from "../../Bridge/BridgeIcon";
import MapMarker from "./MapMarker";
import { MapObject, Marker } from "@pasport/react-common";

interface BridgeMarkerProps {
	bridge: MapBridgeDto,
	map: MapObject,
	marker: Marker
}

export default function BridgeMarker({ bridge, map, marker }: BridgeMarkerProps) {

	const [zoomCoef, setZoomCoef] = useState(0);
	const thresholdZoom = 12;
	useEffect(() => {
		const onZoom = () => {
			const a = (map.map.getMinZoom() - map.map.getZoom());
			const b = (map.map.getMinZoom() - thresholdZoom);
			const coef = Math.abs(a / b);

			setZoomCoef(coef);
		};
		onZoom();
		map.map.on("zoom", onZoom);
		return () => {
			map.map.off("zoom", onZoom);
		};
	});

	return <MapMarker
		icon={<div style={{
			marginTop: 1,
		}}>
			<BridgeIcon />
		</div>}
		map={map}
		marker={marker}
		popup={<BridgeMarkerPopup data={bridge} marker={marker} />}
		color={getBridgeColor(bridge)}
		title={bridge.oznaceni}
		thresholdZoom={12}
		thresholdAlternative={<a style={{
			fontSize: 20,
			WebkitTextStrokeColor: "black",
			WebkitTextStrokeWidth: zoomCoef * 2,
			opacity: Math.pow(zoomCoef, 2) || 0.01,
		}}>
			{/* <strong>∩</strong> */}

			<strong>⨉</strong>
		</a>}
	/>;
}

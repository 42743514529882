import { RoadFilterActionTypes, RoadFilterDispatch, roadFilterReducer, RoadFilterState } from "./roadFilter.utils";
import React, { useReducer } from "react";
import { ROAD_TRIDA_LIST, ROAD_TYPE_LIST, ROAD_USEK_LIST } from "../../../consts/Road";
import { SelectOption, Stack, Select, Button, DeleteIcon, FilterIcon } from "@pasport/react-ui";


const toSelectOption = (values: readonly string[]): SelectOption[] => {
	return values.map((item: string) => ({
		value: item,
		label: item,
	}));

};

const ROAD_TYPE_OPTIONS: SelectOption[] = [
	{
		value: "",
		label: "Zvolte typ",
	},
	...toSelectOption(ROAD_TYPE_LIST),
];


const ROAD_TRIDA_OPTIONS: SelectOption[] = [
	{
		value: "",
		label: "Zvolte třídu",
	},
	...toSelectOption(ROAD_TRIDA_LIST),
];

const ROAD_USEK_OPTIONS: SelectOption[] = [
	{
		value: "",
		label: "Zvolte úsek",
	},
	...toSelectOption(ROAD_USEK_LIST),
];

interface RoadFilterModalContentProps {

	onClose: () => void,
	dispatch: RoadFilterDispatch,
	filterState: RoadFilterState
}

export const RoadFilterModalContent = ({ filterState, dispatch, onClose }: RoadFilterModalContentProps) => {
	const [stateLocal, dispatchLocal] = useReducer(roadFilterReducer, filterState);

	const handleSubmit = () => {
		dispatch({ type: RoadFilterActionTypes.SET_ADVANCED_FILTERS, payload: stateLocal });
		onClose();
	};

	const handleReset = () => {
		dispatch({ type: RoadFilterActionTypes.RESET_FILTERS });
		onClose();
	};

	return (
		<Stack space={3}>

			<div>
				<label className="form-label mb-1 float-start text-nowrap">
					<small>Typ místní komunikace</small>
				</label>
				<Select
					onChange={(value) => dispatchLocal({
						type: RoadFilterActionTypes.SET_FILTER_TYP,
						payload: value,
					})}
					options={ROAD_TYPE_OPTIONS}
					value={stateLocal.typ}
				/>
			</div>

			<div>

				<label className="form-label mb-1 float-start text-nowrap">
					<small>Třída místní komunikace</small>
				</label>
				<Select
					onChange={(value) => dispatchLocal({
						type: RoadFilterActionTypes.SET_FILTER_TRIDA,
						payload: value,
					})}
					options={ROAD_TRIDA_OPTIONS}
					value={stateLocal.trida}
				/>
			</div>

			<div>

				<label className="form-label mb-1 float-start text-nowrap">
					<small>Úsek místní komunikace</small>
				</label>
				<Select
					onChange={(value) => dispatchLocal({
						type: RoadFilterActionTypes.SET_FILTER_USEK,
						payload: value,
					})}
					options={ROAD_USEK_OPTIONS}
					value={stateLocal.usek}
				/>
			</div>
			<Stack direction="row" spaceBetween>
				<Button color="danger" variant="outlined" onPress={handleReset} icon={<DeleteIcon />}>
					Zrušit filtry
				</Button>
				<Button onPress={handleSubmit} icon={<FilterIcon />}>
					Filtrovat
				</Button>

			</Stack>
		</Stack>
	);
};
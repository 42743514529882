import { SORT_DIRECTION, LayoutElement, SortingContainer, SORT_DIRECTION_SELECT_OPTIONS } from "@pasport/react-common";
import { Stack, SearchInput, Select } from "@pasport/react-ui";
import {PARKING_SORT_OPTIONS_KEYS, PARKING_SORT_OPTIONS_SELECT} from "./parkingFilter.utils";

interface RoadListFilterProps {
    setSortDirection: (direction: SORT_DIRECTION) => void;
    setSortMethod: (sortMethod: PARKING_SORT_OPTIONS_KEYS) => void;
    setSearchQuery: (query: string) => void,
    searchQuery: string,
}


export const ParkingListFilter = ({
                                      setSearchQuery,
                                      setSortMethod,
                                      setSortDirection,
                                      searchQuery
                                  }: RoadListFilterProps) => {
    return (
        <Stack direction={'row'} spaceBetween flexWrap>
            <Stack space={2} direction='row'>
                <LayoutElement width={350}>
                    <SearchInput placeholder={"Hledat dle názvu, označení či komunikace"}
                                 onTextChange={setSearchQuery} value={searchQuery}/>
                </LayoutElement>
            </Stack>
            <Stack direction='row' space={3}>
                <SortingContainer>
                    <Select
                        onChange={setSortMethod}
                        options={PARKING_SORT_OPTIONS_SELECT}
                    />
                    <Select
                        onChange={setSortDirection}
                        options={SORT_DIRECTION_SELECT_OPTIONS}
                    />
                </SortingContainer>
            </Stack>
        </Stack>
    )
}
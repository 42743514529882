import {BridgeDto, MapBridgeDto} from "../../../interfaces/BridgeDto";
import {Marker} from "maps-api";
import {MarkerPopup} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../../core/routing";

interface BridgeMarkerPopupProps {
    open?: boolean;
    data: MapBridgeDto;
    marker: Marker;
}

// Označení
// 			Komunikace
// 			Druh MO
// 			Typ mostu
export default function BridgeMarkerPopup({open = false, data, marker}: BridgeMarkerPopupProps) {
    const detailUrl = getAbsoluteRoutePath("most", {
        mostId: data.id,
        uzemiId: data.uzemiId,
    });

    return !open ? (
        <></>
    ) : (
        <div>
            <MarkerPopup
                subtitle="Mostní objekt"
                title={data.oznaceni}
                items={[
                    {label: "Komunikace", value: data.komunikace},
                    {label: "Druh MO", value: data.druh},
                    {label: "Typ mostu", value: data.typ},
                ]}
                detailUrl={detailUrl}
                marker={marker}
            />
        </div>
    );
}

import {DiaryEntryAdvancedDetailForRoad,} from "../../interfaces";
import React, {useMemo} from "react";
import {hasLauTownDistricts, useActiveLau} from "@pasport/react-common";
import {
    withCommonDiaryOptions,
    DiaryEntryAdvancedDto,
    DiaryListAdvanced,
    FulltextSearchOption
} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


const getAdvancedDiaryLinkForRoad = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForRoad>) => {
    return getAbsoluteRoutePath('mistniKomunikaceHlaseni', {
        uzemiId: diaryEntry.detail.uzemiId,
        mistniKomunikaceId: diaryEntry.detail.komunikaceId,
        mistniKomunikaceHlaseniId: diaryEntry.value.id
    })
}

const getAdvancedDiaryLabelForRoad = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForRoad>) => {
    return (
        <>
            <strong>{diaryEntry.detail.uzemiNazev}</strong>
            {" - Komunikace "}<strong>{diaryEntry.detail.oznacenikomunikace}</strong>
        </>
    )
}


interface RoadAllDiaryListProps {
    items: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForRoad>[]
}

export const RoadAllDiaryList = ({items}: RoadAllDiaryListProps) => {
    const {activeLau} = useActiveLau();

    const searchOptions = useMemo(() => {
        const hasDistricts = hasLauTownDistricts(activeLau)
        const detailOptions = {
            'DISTRICT': {
                option: hasDistricts ? "Městská část" : "Katastrální území",
                placeholder: hasDistricts ? "Hledat dle městské části ..." : 'Hledat dle katastrálního území ...',
                getSearchStrings: (diary) => {
                    return [diary.detail.uzemiNazev];
                }
            },
            'ROAD': {
                option: "Komunikace",
                placeholder: "Hledat dle komunikace ...",
                getSearchStrings: (diary) => {
                    return [diary.detail.oznacenikomunikace || ''];
                }
            },
        } as Record<string, FulltextSearchOption<DiaryEntryAdvancedDetailForRoad>>;

        return withCommonDiaryOptions(detailOptions);

    }, [activeLau])

    return (
        <DiaryListAdvanced
            items={items}
            getLink={getAdvancedDiaryLinkForRoad}
            getLabel={getAdvancedDiaryLabelForRoad}
            searchOptions={searchOptions}
        />
    )
}
import {getTownDistrictList} from "@apis/TownDistrict";
import {TownDistrictDto} from "../../interfaces";
import {ApiState, createApiState} from "@pasport/react-common";
import {useReduxApiStateEffect} from "../../redux";

export const useTownDistrict = () => {
    const [townDistrictListApiState] = useReduxApiStateEffect("townDistricts", () => getTownDistrictList());

    const getTownDistrictDetail = (districtId: number) => {
        const data: TownDistrictDto | undefined = townDistrictListApiState?.data?.find(item => item.id === districtId);
        const isError = townDistrictListApiState?.loading === false && !Boolean(data);
        const detailApiState: ApiState<TownDistrictDto> = createApiState<TownDistrictDto>({
            ...townDistrictListApiState,
            success: isError ? false : townDistrictListApiState.success,
            error: isError ? {
                status: 404,
                url: "undefined",
                body: "Unknown district",
            } : townDistrictListApiState.error,
            data: data || null,
        });
        return detailApiState;
    };

    return {
        townDistrictListApiState,
        getTownDistrictDetail,
    };
};
import {useParams} from "react-router-dom";
import {RouterParamName} from "./routePaths";

export const useUrlParams = (): Record<RouterParamName, number> => {
    const params = useParams<RouterParamName>();
    return {
        uzemiId: Number(params.uzemiId),
        
        mistniKomunikaceId: Number(params.mistniKomunikaceId),
        mistniKomunikaceHlaseniId: Number(params.mistniKomunikaceHlaseniId),

        mostId: Number(params.mostId),
        mostHlaseniId: Number(params.mostHlaseniId),

        parkovisteId: Number(params.parkovisteId),
        parkovisteHlaseniId: Number(params.parkovisteHlaseniId),
    };
}
import {RoadDto} from "../../interfaces";
import {RoadItem} from "./RoadItem";
import {useRoadFilter} from "./RoadFilter";
import {RoadListFilter} from "./RoadFilter/RoadListFilter";
import { Spacing, ListContainer, Stack } from "@pasport/react-common";


interface RoadListProps {
    items: RoadDto[],
    getLink: (sign: RoadDto) => string
}

export const RoadList = ({items, getLink}: RoadListProps) => {
    const {filteredItems, ...restProps} = useRoadFilter(items);

    return <>
        <RoadListFilter
            {...restProps}
        />
        <Spacing space={2}/>
        <ListContainer
            nextPage={100}
            items={filteredItems}
            render={(renderItems) => (
                <Stack space={2}>
                    {renderItems.map(road => <RoadItem value={road} key={road.id} link={getLink(road)}/>)}
                </Stack>
            )}
            emptyLabel={'Filtrům neodpovídá žádná místní komunikace.'}
        />

    </>
}
import {MapRoadDto, RoadDto, RoadPovrch, RoadSearchResultDto, RoadTrida, RoadTyp, RoadUsek} from "../../interfaces";
import {MapRoadApiDto, RoadApiInDto, RoadApiOutDto, RoadNameApiDto, RoadSearchResultApiDto} from "./RoadApi.dto";
import {ROAD_PROVOZ_LIST, RoadProvoz, RoadUmisteni} from "../../consts/Road";
import { apiToNumber, apiToString, apiToFloatNumber, apiToDate, apiToGeometry, stringToApi, numberToApi, dateToApi, convertInnerLocationsToGPS, convertStringToGeometry } from "@pasport/react-common";

const apiToRoadTrida = (api: string): RoadTrida => api as RoadTrida;
const apiToRoadTyp = (api: string): RoadTyp => api as RoadTyp;

export const mapApiToRoadDto = (api: RoadApiInDto): RoadDto => {
    return {
        id: apiToNumber(api.komunikace_id),
        nazev: apiToString(api.nazev),
        oznaceni: apiToString(api.oznaceni),
        typ: apiToRoadTyp(apiToString(api.typ_kom)),
        trida: apiToRoadTrida(apiToString(api.trida_kom)),
        usek: apiToString(api.typ_useku) as RoadUsek,
        vlastnik: apiToString(api.vlastnik_kom),
        povrch: apiToString(api.prevazujici_povrch) as RoadPovrch,
        stav: apiToString(api.stav),
        delka: apiToFloatNumber(api.delka),
        plocha: apiToFloatNumber(api.plocha_m2),
        prumernaSirka: apiToFloatNumber(api.prum_sirka),
        provoz: apiToString(api.provoz) as RoadProvoz,
        pocetPruhu: apiToNumber(api.pocet_pruhu),
        umisteni: apiToString(api.umisteni) as RoadUmisteni,
        pocetMostichObjektu: apiToNumber(api.pocet_mo),
        pocetParkovist: apiToNumber(api.pocet_par),
        parcely: apiToString(api.parcely),
        vlastniciParcel: apiToString(api.parcely_vlastnici),
        datumVystavby: apiToDate(api.datum_vystavby),
        datumMapovani: apiToDate(api.datum_mapovani),
        datumAktualizace: apiToDate(api.datum_aktualizace),
        geometry: apiToGeometry(api.geom),
        geometryString: apiToString(api.geom),
        datumPosledniZavady: apiToDate(api.datum_posl_zavady),
        datumPosledniUdrzby: apiToDate(api.datum_posl_servisu),
        zimniUdrzba: apiToString(api.zimni_udrzba),
        dalsiInformace: apiToString(api.dalsi_info),
        poznamka: apiToString(api.poznamka),
    }
}

const getRoadProvoz = (dto: RoadDto): RoadProvoz => {
    switch (dto.typ) {
        case "chodník":
            return "pěší";
        case "cyklostezka":
            return "cyklistický";
        case "chodník + cyklostezka":
            return "pěší + cyklistický";
        case "vozovka":
        default:
            return ROAD_PROVOZ_LIST.includes(dto.provoz) ? dto.provoz : "neurčeno";

    }
}

const getRoadUmisteni = (dto: RoadDto): RoadUmisteni => {
    if (dto.typ === 'vozovka')
        return '-';
    else
        return dto.umisteni;
}

export const mapRoadDtoToApi = (dto: RoadDto): RoadApiOutDto => {
    return {
        oznaceni: stringToApi(dto.oznaceni),
        nazev: stringToApi(dto.nazev),
        typ_kom: stringToApi(dto.typ),
        trida_kom: stringToApi(dto.trida),
        typ_useku: stringToApi(dto.usek),
        vlastnik_kom: stringToApi(dto.vlastnik),
        prevazujici_povrch: stringToApi(dto.povrch),
        stav: stringToApi(dto.stav),
        IXdelka: numberToApi(dto.delka),
        IXprum_sirka: numberToApi(dto.prumernaSirka),
        provoz: stringToApi(getRoadProvoz(dto)),
        IXpocet_pruhu: numberToApi(dto.pocetPruhu),
        umisteni: stringToApi(getRoadUmisteni(dto)),
        parcely: stringToApi(dto.parcely),
        parcely_vlastnici: stringToApi(dto.vlastniciParcel),
        DXdatum_vystavby: dateToApi(dto.datumVystavby),
        DXdatum_mapovani: dateToApi(dto.datumMapovani),
        DXdatum_aktualizace: dateToApi(dto.datumAktualizace),
        zimni_udrzba: stringToApi(dto.zimniUdrzba),
        dalsi_info: stringToApi(dto.dalsiInformace),
        poznamka: stringToApi(dto.poznamka),
    }
}

export const mapApiToMapRoadDto = (api: MapRoadApiDto): MapRoadDto => {
    return {
        id: api.komunikace_id,
        nazev: api.nazev,
        oznaceni: api.oznaceni,
        typ: apiToRoadTyp(api.typ_kom),
        trida: apiToRoadTrida(api.trida_kom),
        usek: api.typ_useku,
        uzemiId: api.uzemi_id,
        uzemiNazev: api.ku,
        geometry: convertInnerLocationsToGPS(convertStringToGeometry(`[${api.geom}]`))
    }
}


export const mapApiToRoadNameDto = (response: RoadNameApiDto): string => {
    return response.oznaceni;
}

export const mapApiToRoadSearchResultDto = (api: RoadSearchResultApiDto): RoadSearchResultDto => {
    return {
        id: apiToNumber(api.komunikace_id),
        oznaceni: apiToString(api.oznaceni),
        uzemiId: apiToNumber(api.uzemi_id),
        uzemiNazev: apiToString(api.nazev_ku),
    }
}
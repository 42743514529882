import { SortingFieldOptions, sortMethodString, sortMethodNumber, sortMethodDate, mapSortFieldOptionsToSelectOptions } from "@pasport/react-common";
import {ParkingDto} from "../../../interfaces/ParkingDto";

export type PARKING_SORT_OPTIONS_KEYS =
    'OZNACENI'
    | 'NAZEV'
    | 'KOMUNIKACE'
    | 'POCET_MIST'
    | 'DATUM_POSLEDNI_ZAVADY'
    | 'DATUM_POSLEDNI_UDRZBY';

export const PARKING_SORT_OPTIONS: SortingFieldOptions<PARKING_SORT_OPTIONS_KEYS, ParkingDto> = {
    OZNACENI: {
        label: "Označení",
        method: sortMethodString(obj => obj.oznaceni)
    },
    NAZEV: {
        label: "Název",
        method: sortMethodString(obj => obj.nazev)
    },
    KOMUNIKACE: {
        label: "Komunikace",
        method: sortMethodString(obj => obj.komunikace)
    },
    POCET_MIST: {
        label: "Počet míst",
        method: sortMethodNumber(obj => obj.pocetMist)
    },
    DATUM_POSLEDNI_ZAVADY: {
        label: "Datum poslední závady",
        method: sortMethodDate(road => road.datumPosledniZavady)
    },
    DATUM_POSLEDNI_UDRZBY: {
        label: "Datum poslední údržby",
        method: sortMethodDate(road => road.datumPosledniUdrzby)
    },
};

export const PARKING_SORT_OPTIONS_SELECT = mapSortFieldOptionsToSelectOptions(PARKING_SORT_OPTIONS);
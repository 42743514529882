import { SORT_DIRECTION, LayoutElement, SortingContainer, SORT_DIRECTION_SELECT_OPTIONS } from "@pasport/react-common";
import { Stack, SearchInput, Select } from "@pasport/react-ui";
import {BRIDGE_SORT_OPTIONS_KEYS, BRIDGE_SORT_OPTIONS_SELECT} from "./bridgeFilter.utils";

interface RoadListFilterProps {
    setSortDirection: (direction: SORT_DIRECTION) => void;
    setSortMethod: (sortMethod: BRIDGE_SORT_OPTIONS_KEYS) => void;
    setSearchQuery: (query: string) => void
    setBridgeKind: (kind: string) => void,
    searchQuery: string,
}


export const BridgeListFilter = ({
                                     setSearchQuery,
                                     setSortMethod,
                                     setSortDirection,
                                     setBridgeKind,
                                     searchQuery
                                 }: RoadListFilterProps) => {
    return (
        <Stack direction={'row'} spaceBetween flexWrap>
            <Stack space={2} direction='row'>
                <LayoutElement width={350}>
                    <SearchInput placeholder={"Hledat dle názvu, označení či komunikace"}
                                 onTextChange={setSearchQuery} value={searchQuery}/>
                </LayoutElement>

                <div style={{flexShrink: 1}}>
                    <Select
                        onChange={setBridgeKind}
                        options={[
                            {
                                value: 'all',
                                label: 'Všechny objekty'
                            }, {
                                value: 'most',
                                label: 'Pouze mosty',
                            }, {
                                value: 'propustek',
                                label: 'Pouze propustky'
                            }
                        ]}
                    />

                </div>
            </Stack>
            <Stack direction='row' space={3}>
                <SortingContainer>
                    <Select
                        onChange={setSortMethod}
                        options={BRIDGE_SORT_OPTIONS_SELECT}
                    />
                    <Select
                        onChange={setSortDirection}
                        options={SORT_DIRECTION_SELECT_OPTIONS}
                    />
                </SortingContainer>
            </Stack>
        </Stack>
    )
}
import { formatDate } from "@pasport/react-common";
import { ListItem, Row, RowItem } from "@pasport/react-ui";
import {RoadDto} from "../../interfaces";
import {RoadIcon} from "./RoadIcon";

interface RoadItemProps {
    value: RoadDto,
    link: string
}


export const RoadItem = ({value, link}: RoadItemProps) => {
    return (
        <ListItem link={link} icon={<RoadIcon className='fs-3'/>}>
            <Row>
                <RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
                <RowItem label={"Název"} size={2}>{value.nazev}</RowItem>
                <RowItem label={"Typ"} size={1.5}>{value.typ}</RowItem>
                <RowItem label={"Třída"} size={1.5}>{value.trida}</RowItem>
                <RowItem label={"Úsek"} size={1.5} priority={9}>{value.usek}</RowItem>
                <RowItem label={"Poslední závada"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniZavady)}</RowItem>
                <RowItem label={"Poslední údržba"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
                <RowItem label={"Poznámka"} size={1.5} priority={9}>{value.poznamka}</RowItem>
                {/*FIXME its not 12 size*/}
            </Row>
        </ListItem>
    );
}
import { getMapRoadList } from "@apis/Road";
import RoadMarker from "../../components/map/markers/RoadMarker";
import { MapRoadDto, RoadTrida, RoadTyp } from "../../interfaces";
import { MapObject, useApiStateEffect } from "@pasport/react-common";
// Poprosím přehodit barvy typů komunikací,
//uvědomil jsem si, že modrá barva není moc štastné řešení.
//Modrou bych dal Chodník + cyklostezka a fialovou MK III. Třídy.
//Zároveň bych dal obě barvy o něco slabší, ať je vidno jasněji grafiku symbolu a popis.

export const getRoadColor = (road: MapRoadDto) => {
	switch (road.typ) {
		case "chodník":
			return "lightgreen";
		case "cyklostezka":
			return "pink";
		case "chodník + cyklostezka":
			return "#39B8E7";
	}

	switch (road.trida) {
		case RoadTrida.TRIDA_1:
			return "orange";
		case RoadTrida.TRIDA_2:
			return "yellow";
		case RoadTrida.TRIDA_3:
			return "#E339E7";
		case RoadTrida.TRIDA_4:
			return "green";
		case RoadTrida.UCELOVA_KOMUNIKACE:
			return "brown";
		default:
			return "black";
	}
};

type StateType = {
	roads: MapRoadDto[];
};

export function useMapRoads() {
	const [state] = useApiStateEffect<StateType>(async () => {
		return {
			roads: await getMapRoadList(0),
		};
	});

	const addToMap = (map: MapObject, data: StateType) => {
		const layers = {
			roadsLayer: map.createLayer(),
			roadsVozovkaLayer: map.createLayer(),
			roadsChodnikLayer: map.createLayer(),
			roadsCyklostezkaLayer: map.createLayer(),
			roadsChodnikCyklostezkaLayer: map.createLayer(),
			roadsTrida1Layer: map.createLayer(),
			roadsTrida2Layer: map.createLayer(),
			roadsTrida3Layer: map.createLayer(),
			roadsTrida4Layer: map.createLayer(),
			roadsUcelovaKomunikaceLayer: map.createLayer(),
		};

		layers.roadsLayer.add(layers.roadsVozovkaLayer);
		layers.roadsLayer.add(layers.roadsChodnikLayer);
		layers.roadsLayer.add(layers.roadsCyklostezkaLayer);
		layers.roadsLayer.add(layers.roadsChodnikCyklostezkaLayer);
		layers.roadsVozovkaLayer.add(layers.roadsTrida1Layer);
		layers.roadsVozovkaLayer.add(layers.roadsTrida2Layer);
		layers.roadsVozovkaLayer.add(layers.roadsTrida3Layer);
		layers.roadsVozovkaLayer.add(layers.roadsTrida4Layer);
		layers.roadsVozovkaLayer.add(layers.roadsUcelovaKomunikaceLayer);

		const objects = data.roads.map((road) => {
			const geometry = map
				.createGeometryMarker(
					road.geometry,
					"polygon",
					(marker) => {
						return <RoadMarker road={road} marker={marker} map={map} />;
					},
					{
						useAverageLocation: false,
					},
				)
				.initialize();
			geometry.setUseAverageLocation(true);
			geometry.setStyle({
				color: getRoadColor(road),
				weight: 4,
				fillColor: getRoadColor(road),
				fillOpacity: 0.5,
			});
			geometry.setActive(true);

			switch (road.typ) {
				case RoadTyp.VOZOVKA:
					// geometry.addToLayer(sublayers[0]);
					switch (road.trida) {
						case RoadTrida.TRIDA_1:
							geometry.addToLayer(layers.roadsTrida1Layer);
							break;
						case RoadTrida.TRIDA_2:
							geometry.addToLayer(layers.roadsTrida2Layer);
							break;
						case RoadTrida.TRIDA_3:
							geometry.addToLayer(layers.roadsTrida3Layer);
							break;
						case RoadTrida.TRIDA_4:
							geometry.addToLayer(layers.roadsTrida4Layer);
							break;
						case RoadTrida.UCELOVA_KOMUNIKACE:
							geometry.addToLayer(layers.roadsUcelovaKomunikaceLayer);
							break;
						default:
							geometry.addToLayer(layers.roadsVozovkaLayer);
							break;
					}
					break;
				case RoadTyp.CHODNIK:
					geometry.addToLayer(layers.roadsChodnikLayer);
					break;
				case RoadTyp.CYKLOSTEZKA:
					geometry.addToLayer(layers.roadsCyklostezkaLayer);
					break;
				case RoadTyp.CHODNIK_CYKLOSTEZKA:
					geometry.addToLayer(layers.roadsChodnikCyklostezkaLayer);
					break;
				default:
					geometry.addToLayer(layers.roadsLayer);
					break;
			}

			return geometry;
		});

		for (const layer of Object.values(layers)) {
			layer.initialize();
		}

		return layers;
	};

	return {
		state: state,
		add: addToMap,
	};
}

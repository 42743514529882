import {
	fetchEntityList,
	fetchEntityDetail,
	fetchCreateEntity,
	fetchUpdateEntity,
	fetchDeleteEntity,
	downloadFile,
	normalizeText,
	fetchUploadFile,
	FileDto,
	fetchDeleteFile,
	apiToNumber,
	apiToString,
	fetchToJson,
	geometryToApi,
	mapDiaryEntryToApi,
	mapApiToDiaryEntry,
	DiaryEntryAdvancedDto,
	DiaryEntryDto,
	mapApiToDiaryEntryAdvanced,
	mapApiToPhoto,
	dateToString,
} from "@pasport/react-common";
import { FileUploadDto } from "@pasport/react-common/dist/layout/FileListCard";
import {
	DiaryEntryAdvancedDetailForRoad,
	MapRoadDto,
	RoadDto, RoadSearchResultDto, TownDistrictDto,
} from "../../interfaces";


import { DiaryEntryAdvancedRoadApiDto, MapRoadApiDto } from "./RoadApi.dto";
import {
	mapApiToMapRoadDto,
	mapApiToRoadDto,
	mapApiToRoadNameDto,
	mapApiToRoadSearchResultDto,
	mapRoadDtoToApi,
} from "./RoadApi.map";
import { LocationPoint } from "maps-api/lib/core/LocationPoint";

export const getRoadList = async (districtId: number): Promise<RoadDto[]> => {

	return fetchEntityList(`SeznamMK/${districtId}`, mapApiToRoadDto);
};

export const getRoadDetail = (roadId: number, districtId: number) => {
	return fetchEntityDetail(`DetailMK/${districtId}/${roadId}`, mapApiToRoadDto);
};

export const createRoad = (road: RoadDto, districtId: number) => {
	return fetchCreateEntity("insertMK", {
		...mapRoadDtoToApi(road),
		uzemi_id: districtId,
	}).then(async roadId => {
		if (road.geometry) {
			await updateRoadPosition(
				road.geometry,
				roadId, districtId,
			);
		}
		return roadId;
	});
};

export const updateRoad = (road: RoadDto, districtId: number) => {
	return fetchUpdateEntity("updateMKdata", {
		...mapRoadDtoToApi(road),
		komunikace_id: road.id,
		uzemi_id: districtId,

	}).then(() => {
		return updateRoadPosition(road.geometry, road.id, districtId);
	});
};

export const deleteRoad = (roadId: number, districtId: number) => {
	return fetchDeleteEntity("deleteMK", {
		"MkId": roadId,
		"uzemi": districtId,
	});
};

//download
export const downloadRoadListTable = async (district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_mistni_komunikace_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportMK/${district.id}`, fileName, true);
};


//photos

export const getRoadPhotoList = async (roadId: number, districtId: number) => {
	return fetchEntityList(`SeznamFot/${districtId}/${roadId}`, mapApiToPhoto);
};

export const uploadRoadPhoto = async (file: FileUploadDto, roadId: number, districtId: number) => {
	return fetchUploadFile(file, {
		case: "MK",
		MkId: roadId,
	});
};

export const deleteRoadPhoto = async (file: FileDto, roadId: number, districtId: number) => {
	return fetchDeleteFile("deleteFileMK", file, {
		MkId: roadId,
		uzemi: districtId,
	});
};


//diaries

export const getRoadDiaryEntryList = (roadId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniMK/${districtId}/${roadId}`, mapApiToDiaryEntry);
};


export const createRoadDiaryEntry = async (diaryEntry: DiaryEntryDto, roadId: number, districtId: number) => {
	return fetchCreateEntity("insertHlaseniMK", {
		...mapDiaryEntryToApi(diaryEntry, true),
		komunikace_id: roadId,
		IXopraveno: "0",
	});
};

export const getRoadDiaryEntryDetail = async (diaryEntryId: number, roadId: number, districtId: number) => {
	return fetchEntityDetail(`DetailHlaseniMK/${districtId}/${roadId}/${diaryEntryId}`, mapApiToDiaryEntry);
};

export const updateRoadDiaryEntry = async (diaryEntry: DiaryEntryDto, roadId: number, districtId: number) => {
	return fetchUpdateEntity("updateHlaseniMK", {
		...mapDiaryEntryToApi(diaryEntry, false),
		hlaseni_id: diaryEntry.id,
		komunikace_id: roadId,
		uzemi_id: districtId,

	});
};

export const deleteRoadDiaryEntry = async (diaryEntryId: number, roadId: number, districtId: number) => {
	return fetchDeleteEntity("deleteHlaseniMK", {
		hlaseniId: diaryEntryId,
		MkId: roadId,
		uzemi: districtId,
	});
};

export const getRoadDiaryEntryRelatedList = (parentDiaryId: number, roadId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniMKdt/${districtId}/${roadId}/${parentDiaryId}`, mapApiToDiaryEntry);
};


//diary photos

export const getRoadDiaryPhotoList = async (diaryEntryId: number, roadId: number, districtId: number) => {
	return fetchEntityList(`SeznamFotHlasMK/${districtId}/${roadId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadRoadDiaryPhoto = async (file: FileUploadDto, diaryEntryId: number) => {
	// TODO: FIXME: this is not working
	return fetchUploadFile(file, {
		case: "HlaseniMK",
		hlaseni_id: diaryEntryId,

	});
};

export const deleteRoadDiaryPhoto = async (file: FileDto, diaryEntryId: number, roadId: number, districtId: number) => {
	return fetchDeleteFile(`deleteFileHlaseniMK`, file, {
		hlaseni_id: diaryEntryId,
		komunikace_id: roadId,
		uzemi: districtId,

	});
};

//all diaries
export const getAllRoadDiaryEntryList = async (): Promise<DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForRoad>[]> => {
	return fetchEntityList(`SeznamHlaseniMKlau`, (response: DiaryEntryAdvancedRoadApiDto) => mapApiToDiaryEntryAdvanced(response,
		(data) => ({
			uzemiId: apiToNumber(data.uzemi_id),
			uzemiNazev: apiToString(data.nazev_ku),
			komunikaceId: apiToNumber(data.komunikace_id),
			oznacenikomunikace: apiToString(data.oznaceni_mk),
		}),
	));
};


// map
export const getMapRoadList = async (districtId: number): Promise<MapRoadDto[]> => {
	const list: MapRoadApiDto[] = await fetchToJson(`MapaMK`)
		.then((r) => {
			return r.data;
		});
	const result = list.map((item) => mapApiToMapRoadDto(item));
	return result;
};

//position
export const updateRoadPosition = (geometry: LocationPoint[][] | null, roadId: number, districtId: number) => {

	return fetchUpdateEntity("updateSPIMK", {
		grafika: "polygon",
		geom: geometryToApi(geometry),
		MkId: roadId,
	});
};

//helpers
export const getAllRoadNamesInLau = async (): Promise<string[]> => {
	return fetchEntityList(`SeznamMKLau`, mapApiToRoadNameDto);
};


export const getRoadListByQuery = async (query: string): Promise<RoadSearchResultDto[]> => {
	return fetchEntityList(`VyhledMK/${query}`, mapApiToRoadSearchResultDto);
};
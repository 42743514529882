import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux";
import { AppRouter } from "./routers";
import {
	ActivityInfoProvider,
	AlertProvider,
	AuthProvider,
	BreadcrumbsProvider,
	MapProvider,
	Navbar,
	RefreshTokenGuard,
} from "@pasport/react-common";
import { Provider } from "react-redux";
import { setAppConfig } from "./core/config";

setAppConfig();

function App() {
	return (
		<Provider store={store}>
			<AuthProvider>
				<BreadcrumbsProvider>
					<AlertProvider>
						<MapProvider>
							<ActivityInfoProvider>
								<div className="h-100 d-flex flex-column">
									<Router>
										<Navbar title="Místní komunikace" />
										<div className="flex-grow-1">
											<RefreshTokenGuard>
												<AppRouter />
											</RefreshTokenGuard>
										</div>
									</Router>
								</div>
							</ActivityInfoProvider>
						</MapProvider>
					</AlertProvider>
				</BreadcrumbsProvider>
			</AuthProvider>
		</Provider>
	);
}

export default App;

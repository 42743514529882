
import { SortingFieldOptions, sortMethodString, sortMethodDate, mapSortFieldOptionsToSelectOptions, SORT_DIRECTION } from "@pasport/react-common";
import {RoadDto} from "../../../interfaces";
import {Dispatch} from "react";

export type ROAD_SORT_OPTIONS_KEYS = 'OZNACENI' | 'NAZEV' | 'DATUM_POSLEDNI_ZAVADY' | 'DATUM_POSLEDNI_UDRZBY';

export const ROAD_SORT_OPTIONS: SortingFieldOptions<ROAD_SORT_OPTIONS_KEYS, RoadDto> = {
    OZNACENI: {
        label: "Označení",
        method: sortMethodString(road => road.oznaceni)
    },
    NAZEV: {
        label: "Název",
        method: sortMethodString(road => road.nazev)
    },
    DATUM_POSLEDNI_ZAVADY: {
        label: "Datum poslední závady",
        method: sortMethodDate(road => road.datumPosledniZavady)
    },
    DATUM_POSLEDNI_UDRZBY: {
        label: "Datum poslední údržby",
        method: sortMethodDate(road => road.datumPosledniUdrzby)
    },
};

export const ROAD_SORT_OPTIONS_SELECT = mapSortFieldOptionsToSelectOptions(ROAD_SORT_OPTIONS);

export enum RoadFilterActionTypes {
    SET_ADVANCED_FILTERS = 'SET_ADVANCED_FILTERS',

    SET_SORT_METHOD = 'SET_SORT_METHOD',
    SET_SORT_DIRECTION = 'SET_SORT_DIRECTION',

    SET_SEARCH = 'SET_SEARCH',

    SET_FILTER_TYP = 'SET_FILTER_TYP',
    SET_FILTER_TRIDA = 'SET_FILTER_TRIDA',
    SET_FILTER_USEK = 'SET_FILTER_USEK',
    RESET_FILTERS = 'RESET_FILTERS',
}

export type RoadFilterState = {
    sortMethod: ROAD_SORT_OPTIONS_KEYS;
    sortDirection: SORT_DIRECTION;

    searchQuery?: string;

    typ?: string;
    trida?: string;
    usek?: string;
}

export type RoadFilterStringAction = {
    type: RoadFilterActionTypes.SET_SEARCH | RoadFilterActionTypes.SET_FILTER_TYP | RoadFilterActionTypes.SET_FILTER_TRIDA | RoadFilterActionTypes.SET_FILTER_USEK;
    payload: string;
}

export type SetSortMethodAction = {
    type: RoadFilterActionTypes.SET_SORT_METHOD;
    payload: ROAD_SORT_OPTIONS_KEYS;
}

export type SetSortDirectionAction = {
    type: RoadFilterActionTypes.SET_SORT_DIRECTION;
    payload: SORT_DIRECTION;
}

export type RoadFilterNoPayloadAction = {
    type: RoadFilterActionTypes.RESET_FILTERS;
}

export type RoadFilterSetAllAction = {
    type: RoadFilterActionTypes.SET_ADVANCED_FILTERS;
    payload: RoadFilterState
}


export type RoadFilterAction =
    RoadFilterStringAction
    | SetSortMethodAction
    | SetSortDirectionAction
    | RoadFilterNoPayloadAction
    | RoadFilterSetAllAction;


export type RoadFilterDispatch = Dispatch<RoadFilterAction>;

export const roadFilterReducer = (state: RoadFilterState, action: RoadFilterAction): RoadFilterState => {

    if (!action) return state;

    switch (action.type) {
        case RoadFilterActionTypes.SET_ADVANCED_FILTERS:
            return {...action.payload, searchQuery: undefined};


        case RoadFilterActionTypes.SET_SORT_METHOD:
            return {...state, sortMethod: action.payload};

        case RoadFilterActionTypes.SET_SORT_DIRECTION:
            return {...state, sortDirection: action.payload};


        case RoadFilterActionTypes.SET_SEARCH:
            return {...state, searchQuery: action.payload};


        case RoadFilterActionTypes.SET_FILTER_TYP:
            return {...state, typ: action.payload};

        case RoadFilterActionTypes.SET_FILTER_TRIDA:
            return {...state, trida: action.payload};

        case RoadFilterActionTypes.SET_FILTER_USEK:
            return {...state, usek: action.payload};

        case RoadFilterActionTypes.RESET_FILTERS:
            return {
                ...state,
                typ: undefined,
                trida: undefined,
                usek: undefined,
                searchQuery: undefined,
            };


        default:
            return state;
    }
}

import { ListContainer, Stack } from "@pasport/react-common";
import {TownDistrictDto} from "../../interfaces";
import {TownDistrictItem} from "./TownDistrictItem";
import React from "react";


interface TownDistrictListProps {
    items: TownDistrictDto[]
}

export const TownDistrictList = ({items}: TownDistrictListProps) => {
    return <>
        <ListContainer
            firstPage={100}
            items={items}
            render={(renderItems) => (
                <Stack space={2}>
                    {renderItems.map(item => <TownDistrictItem district={item}/>)}
                </Stack>

            )}
            emptyLabel={'Neevidujeme zde žádná území.'}
        />
    </>

}
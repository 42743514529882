import { MapParkingDto, ParkingDto } from "../../../interfaces/ParkingDto";
import ParkingMarkerPopup from "../popups/ParkingMarkerPopup";
import { ParkingIcon } from "../../Parking/ParkingIcon";
import MapMarker from "./MapMarker";
import { get } from "http";
import { getParkingColor } from "../../../hooks/map/useMapParkings";
import { MapObject, Marker } from "@pasport/react-common";

interface ParkingMarkerProps {
	parking: MapParkingDto,
	map: MapObject,
	marker: Marker
}

export default function ParkingMarker({
										  parking, map, marker,
									  }: ParkingMarkerProps) {

	return <MapMarker
		icon={<div style={{
			width: 20,
			height: 20,
			marginLeft: 0.5,
		}}>
			<ParkingIcon />
		</div>}
		map={map}
		marker={marker}
		popup={<ParkingMarkerPopup data={parking} marker={marker} />}
		color={getParkingColor(parking)}
		title={parking.oznaceni}
		thresholdZoom={12}
	/>;
}

import { getMapBridgeList } from "@apis/Bridge";
import BridgeMarker from "../../components/map/markers/BridgeMarker";
import { BridgeDruh, MapBridgeDto } from "../../interfaces/BridgeDto";
import { MapObject, useApiStateEffect } from "@pasport/react-common";

export const getBridgeColor = (bridge: MapBridgeDto) => {
	switch (bridge.druh) {
		case BridgeDruh.MOST:
			return "red";
		case BridgeDruh.PROPUSTEK:
			return "orange";
	}
};

type StateType = {
	bridges: MapBridgeDto[];
};

export function useMapBridges() {
	const [state] = useApiStateEffect<StateType>(async () => {
		return {
			bridges: await getMapBridgeList(0),
		};
	});

	const addToMap = (map: MapObject, data: StateType) => {
		const layers = {
			bridgesLayer: map.createLayer(),
			bridgesPropustekLayer: map.createLayer(),
			bridgesMostLayer: map.createLayer(),
		};
		layers.bridgesLayer.add(layers.bridgesPropustekLayer);
		layers.bridgesLayer.add(layers.bridgesMostLayer);

		const objects = data.bridges.map((bridge) => {
			const marker = map
				.createMarker(bridge.location, (marker) => {
					return <BridgeMarker bridge={bridge} marker={marker} map={map} />;
				})
				.initialize();
			marker.setActive(true);
			switch (bridge.druh) {
				case BridgeDruh.MOST:
					marker.addToLayer(layers.bridgesMostLayer);
					break;
				case BridgeDruh.PROPUSTEK:
					marker.addToLayer(layers.bridgesPropustekLayer);
					break;
				default:
					marker.addToLayer(layers.bridgesLayer);
			}
			return marker;
		});

		// Initialize layers
		for (const layer of Object.values(layers)) {
			layer.initialize();
		}

		return layers;
	};

	return {
		state: state,
		add: addToMap,
	};
}

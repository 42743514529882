import {RoadDto, RoadTrida, RoadTyp, RoadUsek} from "../../interfaces";
import {useNavigate} from "react-router-dom";
import {createRoad} from "@apis/Road";
import {RoadCards} from "./RoadCards";
import {useRoadNames} from "../../hooks/useRoadNames";
import {useAlert, FormWithCreate, FormMode, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";

const defaultRoad: Omit<RoadDto, 'typ' | 'trida' | 'usek'> & { typ: null | RoadTyp, trida: null | RoadTrida, usek: null | RoadUsek } = {
    id: 0,
    oznaceni: "",
    nazev: "",
    typ: null, //todo
    trida: null,
    geometry: [],//FIXME should not be required
    geometryString: "",
    vlastnik: "",
    datumVystavby: null,
    datumMapovani: null,
    datumAktualizace: null,
    datumPosledniZavady: null,
    datumPosledniUdrzby: null,
    zimniUdrzba: "",
    dalsiInformace: "",
    poznamka: "",
    parcely: "",
    vlastniciParcel: "",
    pocetParkovist: 0,
    pocetMostichObjektu: 0,
    umisteni: "-",
    pocetPruhu: 2,
    provoz: "neurčeno",
    prumernaSirka: 0,
    plocha: 0,
    delka: 0,
    stav: "",
    povrch: "nezjištěno",
    usek: null,


};


interface RoadFormCreateProps {
    districtId: number,
    onCreate?: () => void,
}

export const RoadFormCreate = ({districtId, onCreate}: RoadFormCreateProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();
    const {invalidateRoadNames} = useRoadNames();

    return (
        <FormWithCreate
            initValues={defaultRoad}
            dispatchCreate={(value) => createRoad(value as RoadDto, districtId)}
            onCreateSuccess={(roadId: number) => {
                addSuccessAlert({message: "Byla vytvořena místní komunikace."});
                navigate(
                    getAbsoluteRoutePath('mistniKomunikace', {
                        uzemiId: districtId,
                        mistniKomunikaceId: roadId,
                    })
                );
                invalidateRoadNames();
                if (onCreate) onCreate();
            }}
        >
            {() => <RoadCards mode={FormMode.EDIT} version={FormVersion.CREATE}/>}
        </FormWithCreate>
    )

}



import {
    ROAD_SORT_OPTIONS_KEYS,
    ROAD_SORT_OPTIONS_SELECT,
    RoadFilterDispatch,
    RoadFilterState
} from "./roadFilter.utils";
import React from "react";
import {RoadFilterModalButton} from "./RoadFilterModalButton";
import { SORT_DIRECTION, LayoutElement, SORT_DIRECTION_SELECT_OPTIONS, SortingContainer } from "@pasport/react-common";
import { Stack, SearchInput, Select } from "@pasport/react-ui";

interface RoadListFilterProps {
    setSortDirection: (direction: SORT_DIRECTION) => void;
    setSortMethod: (sortMethod: ROAD_SORT_OPTIONS_KEYS) => void;
    setSearchQuery: (query: string) => void;
    dispatch: RoadFilterDispatch,
    filterState: RoadFilterState
}


export const RoadListFilter = ({
                                   setSearchQuery,
                                   setSortMethod,
                                   setSortDirection,
                                   dispatch,
                                   filterState
                               }: RoadListFilterProps) => {
    return (
        <Stack direction={'row'} spaceBetween flexWrap>
            <Stack space={2} direction='row'>
                <LayoutElement width={300}>
                    <SearchInput placeholder={"Hledejte dle názvu či označení"} onTextChange={setSearchQuery}
                                 value={filterState.searchQuery ?? ''}/>
                </LayoutElement>
                <RoadFilterModalButton dispatch={dispatch} filterState={filterState}/>
            </Stack>
            <SortingContainer>
                <Select
                    onChange={setSortMethod}
                    options={ROAD_SORT_OPTIONS_SELECT}
                />
                <Select
                    onChange={setSortDirection}
                    options={SORT_DIRECTION_SELECT_OPTIONS}
                />
            </SortingContainer>
        </Stack>
    )
}
import {useNavigate} from "react-router-dom";
import {BridgeDto} from "../../interfaces/BridgeDto";
import {BridgeCards} from "./BridgeCards";
import {deleteBridge, deleteBridgePhoto, getBridgePhotoList, updateBridge, uploadBridgePhoto} from "@apis/Bridge";
import {useBridgeNames} from "../../hooks/useBridgeNames";
import {getRoadListByQuery} from "@apis/Road";
import {useAlert, FormWithUpdate, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


interface BridgeEditableDetailProps {
    detail: BridgeDto,
    districtId: number
}

export const BridgeFormEditable = ({detail, districtId}: BridgeEditableDetailProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert, addErrorAlert} = useAlert();
    const {invalidateBridgeNames} = useBridgeNames();

    const navigateToRoadDetail = async (query: string) => {
        const roadList = await getRoadListByQuery(query);
        const road = roadList?.[0];
        if (road) {
            navigate(getAbsoluteRoutePath('mistniKomunikace', {uzemiId: road.uzemiId, mistniKomunikaceId: road.id}));
        } else {
            addErrorAlert({message: 'Omlouváme se, ale komunikace nebyla nalezena.'});
        }
    }

    return (
        <FormWithUpdate
            initValues={detail}
            dispatchUpdate={(updated) => updateBridge(updated, districtId)}
            dispatchDelete={() => deleteBridge(detail.id, districtId)}
            onUpdateSuccess={() => {
                addSuccessAlert({message: 'Mostní objekt byl upraven.'});
                invalidateBridgeNames();
            }}
            onDeleteSuccess={() => {
                addSuccessAlert({message: 'Mostní objekt byl odstraněn.'});
                navigate(getAbsoluteRoutePath('prehledMostu', {uzemiId: districtId}));
                invalidateBridgeNames();
            }}
        >
            {({mode}) => (
                <BridgeCards
                    mode={mode} version={FormVersion.UPDATE}
                    photoApi={{
                        getList: () => getBridgePhotoList(detail.id, districtId),
                        upload: (file) => uploadBridgePhoto(file, detail.id, districtId),
                        delete: (file) => deleteBridgePhoto(file, detail.id, districtId)
                    }}
                    previousBridgeName={detail.oznaceni}
                    onRoadDetailPress={detail.komunikace ? () => navigateToRoadDetail(detail.komunikace) : undefined}
                />
            )}
        </FormWithUpdate>
    )
}
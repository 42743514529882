import {RoutesParamName, RoutesParents, RoutesPaths} from "@pasport/react-common";

type SCREEN_PROPS_VSECHNA_HLASENI = {}


type SCREEN_PROPS_UZEMI = {
    uzemiId: number
}
type SCREEN_PROPS_PREHLED_MISTNI_KOMUNIKACE = SCREEN_PROPS_UZEMI & {};
type SCREEN_PROPS_MISTNI_KOMUNIKACE = SCREEN_PROPS_PREHLED_MISTNI_KOMUNIKACE & {
    mistniKomunikaceId: number
};

type SCREEN_PROPS_MISTNI_KOMUNIKACE_HLASENI = SCREEN_PROPS_MISTNI_KOMUNIKACE & {
    mistniKomunikaceHlaseniId: number
}

type SCREEN_PROPS_PREHLED_MOSTU = SCREEN_PROPS_UZEMI & {};
type SCREEN_PROPS_MOST = SCREEN_PROPS_PREHLED_MOSTU & {
    mostId: number
};
type SCREEN_PROPS_MOST_HLASENI = SCREEN_PROPS_MOST & {
    mostHlaseniId: number
};

type SCREEN_PROPS_PREHLED_PARKOVIST = SCREEN_PROPS_UZEMI & {};
type SCREEN_PROPS_PARKOVISTE = SCREEN_PROPS_PREHLED_PARKOVIST & {
    parkovisteId: number
};
type SCREEN_PROPS_PARKOVISTE_HLASENI = SCREEN_PROPS_PARKOVISTE & {
    parkovisteHlaseniId: number
};

type SCREEN_PROPS_MAP = {}


export type RouterProps = {
    home: undefined,

    uzemi: SCREEN_PROPS_UZEMI,

    prehledMistniKomunikace: SCREEN_PROPS_PREHLED_MISTNI_KOMUNIKACE,
    mistniKomunikace: SCREEN_PROPS_MISTNI_KOMUNIKACE,
    mistniKomunikaceHlaseni: SCREEN_PROPS_MISTNI_KOMUNIKACE_HLASENI,

    prehledMostu: SCREEN_PROPS_PREHLED_MOSTU,
    most: SCREEN_PROPS_MOST,
    mostHlaseni: SCREEN_PROPS_MOST_HLASENI,

    prehledParkovist: SCREEN_PROPS_PREHLED_PARKOVIST,
    parkoviste: SCREEN_PROPS_PARKOVISTE,
    parkovisteHlaseni: SCREEN_PROPS_PARKOVISTE_HLASENI,

    vsechnaHlaseni: SCREEN_PROPS_VSECHNA_HLASENI,

    mapa: SCREEN_PROPS_MAP


};

export type RouterParamName = RoutesParamName<RouterProps>;
export const RouterPaths: RoutesPaths<RouterProps> = {
    home: '',

    uzemi: 'uzemi/:uzemiId',
    prehledMistniKomunikace: 'mistni-komunikace',
    mistniKomunikace: 'komunikace/:mistniKomunikaceId',
    mistniKomunikaceHlaseni: 'hlaseni/:mistniKomunikaceHlaseniId',

    prehledMostu: 'mosty',
    most: 'most/:mostId',
    mostHlaseni: 'hlaseni/:mostHlaseniId',

    prehledParkovist: 'parkoviste',
    parkoviste: 'parkoviste/:parkovisteId',
    parkovisteHlaseni: 'hlaseni/:parkovisteHlaseniId',

    vsechnaHlaseni: 'vsechna-hlaseni',
    mapa: 'mapa',
}


export const RouterParents: RoutesParents<RouterProps> = {
    home: null,

    uzemi: null,

    prehledMistniKomunikace: 'uzemi',
    mistniKomunikace: 'prehledMistniKomunikace',
    mistniKomunikaceHlaseni: 'mistniKomunikace',

    prehledMostu: 'uzemi',
    most: 'prehledMostu',
    mostHlaseni: 'most',

    prehledParkovist: 'uzemi',
    parkoviste: 'prehledParkovist',
    parkovisteHlaseni: 'parkoviste',

    vsechnaHlaseni: null,
    mapa: null,
}



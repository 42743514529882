import {RoadDto} from "../../interfaces";
import {ParkingIcon} from "./ParkingIcon";
import {getParkingListForRoad} from "@apis/Parking";
import {useApiStateEffect, SkeletonLoader, PluralMessage, TabEmptyContent} from "@pasport/react-common";
import {Modal, ListItem, Stack, RowItem} from "@pasport/react-ui";
import {getAbsoluteRoutePath} from "../../core/routing";

interface ParkingSearchModalProps {
    visible: boolean,
    onClose: () => void,
    districtId: number,
    road: RoadDto,
}


export const ParkingSearchModal = ({visible, onClose, road, districtId}: ParkingSearchModalProps) => {


    const [apiState] = useApiStateEffect(() => getParkingListForRoad(districtId, road), [road.id, districtId]);
    return (
        <Modal show={visible} onClose={onClose} colorVariant='light' align={'top'}
               header={(
                   <>
                       <div className="fs-5 fw-bold">Parkoviště u komunikace {road.oznaceni}</div>
                   </>
               )}
        >

            <div className="overflsow-auto">
                <SkeletonLoader
                    data={[apiState]}
                    render={([parkingList]) => (
                        <div className={"d-flex flex-column gap-3"}>
                            {parkingList.length > 0 ? (
                                <div>
                                    <div className="fs-6 p-1 pt-0">
                                        <PluralMessage
                                            count={parkingList.length}
                                            one="Celkem 1 parkoviště"
                                            few="Celkem {count} parkoviště"
                                            many="Celkem {count} parkovišť"
                                        />
                                    </div>
                                    <div className={"d-flex flex-column gap-2"}>
                                        {parkingList.map(parking => (

                                            <ListItem
                                                link={getAbsoluteRoutePath('parkoviste', {
                                                    uzemiId: parking.uzemiId ?? districtId,
                                                    parkovisteId: parking.id
                                                })}
                                                icon={
                                                    <ParkingIcon
                                                        className='fs-3'
                                                        style={{width: 30}}
                                                    />
                                                }
                                            >
                                                <Stack direction='row' spaceBetween center>

                                                    <RowItem
                                                        description={parking.nazev}
                                                        size={5}
                                                        priority={10}
                                                    >
                                                        {parking.oznaceni}
                                                    </RowItem>
                                                </Stack>
                                            </ListItem>
                                        ))}
                                    </div>


                                </div>) : (
                                <TabEmptyContent
                                    text={(
                                        <div>
                                            Komunikace <strong>{road.oznaceni}</strong> neobsahuje žádná parkoviště.
                                        </div>
                                    )}
                                />
                            )}

                        </div>
                    )}
                    renderLoading={() => <TabEmptyContent text={"..."}/>}
                />

            </div>

        </Modal>
    )
}
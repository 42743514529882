import {RoadDto} from "../../interfaces";
import {useNavigate} from "react-router-dom";
import {RoadCards} from "./RoadCards";
import {deleteRoad, deleteRoadPhoto, getRoadPhotoList, updateRoad, uploadRoadPhoto} from "@apis/Road";
import {useRoadNames} from "../../hooks/useRoadNames";
import {BridgeSearchModal} from "../Bridge/BridgeSearchModal";
import {useState} from "react";
import {ParkingSearchModal} from "../Parking/ParkingSearchModal";
import {useAlert, FormWithUpdate, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";


interface RoadEditableDetailProps {
    detail: RoadDto,
    districtId: number
}

export const RoadFormEditable = ({detail, districtId}: RoadEditableDetailProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();

    const {invalidateRoadNames} = useRoadNames();

    const [relatedBridgesVisible, setRelatedBridgesVisible] = useState(false);
    const [relatedParkingsVisible, setRelatedParkingsVisible] = useState(false);

    return (
        <FormWithUpdate
            initValues={detail}
            dispatchUpdate={(updated) => updateRoad(updated, districtId)}
            dispatchDelete={() => deleteRoad(detail.id, districtId)}
            onUpdateSuccess={() => {
                addSuccessAlert({message: 'Místní komunikace byla upravena.'});
                invalidateRoadNames();
            }}
            onDeleteSuccess={() => {
                addSuccessAlert({message: 'Místní komunikace byla odstraněna.'});
                navigate(getAbsoluteRoutePath('prehledMistniKomunikace', {uzemiId: districtId}));
                invalidateRoadNames();
            }}
        >
            {({mode}) => (
                <>
                    <RoadCards
                        mode={mode} version={FormVersion.UPDATE}
                        photoApi={{
                            getList: () => getRoadPhotoList(detail.id, districtId),
                            upload: (file) => uploadRoadPhoto(file, detail.id, districtId),
                            delete: (file) => deleteRoadPhoto(file, detail.id, districtId)
                        }}
                        previousRoadName={detail.oznaceni}

                        onBridgeListPress={(detail.pocetMostichObjektu ?? 0) > 0 ? () => setRelatedBridgesVisible(true) : undefined}
                        onParkingListPress={(detail.pocetParkovist ?? 0) > 0 ? () => setRelatedParkingsVisible(true) : undefined}
                    />
                    <BridgeSearchModal
                        visible={relatedBridgesVisible}
                        onClose={() => setRelatedBridgesVisible(false)}
                        road={detail}
                        districtId={districtId}
                    />
                    <ParkingSearchModal
                        visible={relatedParkingsVisible}
                        onClose={() => setRelatedParkingsVisible(false)}
                        road={detail}
                        districtId={districtId}
                    />
                </>
            )}
        </FormWithUpdate>
    )
}
import {Route, Routes} from "react-router-dom";
import React from "react";
import {useTownDistrict} from "../components/TownDistrict/useTownDistrict";
import {TownDistrictDto} from "../interfaces";
import {ParkingOverviewScreen} from "../screens/ParkingOverviewScreen";
import {ParkingDetailRouter} from "./ParkingDetailRouter";
import {SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


interface ParkingRouterProps {
    detail: TownDistrictDto
}

export const ParkingRouter = ({detail}: ParkingRouterProps) => {
    const {uzemiId} = useUrlParams()
    const {getTownDistrictDetail} = useTownDistrict();
    return <SkeletonLoader
        data={[getTownDistrictDetail(uzemiId)]}
        render={([districtDetail]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('prehledParkovist', {uzemiId})}
                        label={`Parkoviště`}
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <ParkingOverviewScreen detail={districtDetail}/>
                        }/>
                        <Route path={RouterPaths.parkoviste + "/*"} element={
                            <ParkingDetailRouter/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
import { DiaryEntryAdvancedDetailForParking, ParkingDto } from "../../interfaces/ParkingDto";
import { RoadDto, TownDistrictDto } from "../../interfaces";
import { DiaryEntryAdvancedParkingApiDto, MapParkingApiDto } from "./ParkingApi.dto";
import {
	mapApiToMapParkingDto,
	mapApiToParkingDto,
	mapApiToParkingNameDto,
	mapParkingDtoToApi,
} from "./ParkingApi.map";
import { LocationPoint } from "maps-api/lib/core/LocationPoint";
import {
	fetchEntityList,
	fetchEntityDetail,
	fetchCreateEntity,
	fetchUpdateEntity,
	fetchDeleteEntity,
	downloadFile,
	normalizeText,
	fetchUploadFile,
	FileDto,
	fetchDeleteFile,
	apiToNumber,
	apiToString,
	fetchToJson,
	geometryToApi,
	mapDiaryEntryToApi,
	mapApiToDiaryEntry,
	DiaryEntryAdvancedDto,
	DiaryEntryDto,
	mapApiToDiaryEntryAdvanced,
	mapApiToPhoto,
	dateToString,
} from "@pasport/react-common";
import { FileUploadDto } from "@pasport/react-common/dist/layout/FileListCard";

export const getParkingList = (districtId: number): Promise<ParkingDto[]> => {
	return fetchEntityList(`SeznamParUzemi/${districtId}`, mapApiToParkingDto);
};

export const getParkingDetail = (parkingId: number, districtId: number) => {
	return fetchEntityDetail(`DetailPar/${districtId}/${parkingId}`, mapApiToParkingDto);
};

export const createParking = (parking: ParkingDto, districtId: number) => {
	return fetchCreateEntity("insertPAR", {
		...mapParkingDtoToApi(parking),
		uzemi_id: districtId,
	}).then(async parkingId => {
		if (parking.geometry) {
			await updateParkingPosition(
				parking.geometry,
				parkingId, districtId,
			);
		}
		return parkingId;
	});
};

export const updateParking = (parking: ParkingDto, districtId: number) => {
	return fetchUpdateEntity("updatePARdata", {
		...mapParkingDtoToApi(parking),
		ParId: parking.id,
		uzemi_id: districtId,
	}).then(() => updateParkingPosition(parking.geometry, parking.id, districtId));
};

export const deleteParking = (parkingId: number, districtId: number) => {
	return fetchDeleteEntity("PARdelete", {
		"ParId": parkingId,
		"uzemi": districtId,
	});
};

//download
export const downloadParkingListTable = async (district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_parkoviste_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportPAR/${district.id}`, fileName, true);
};

//photos

export const getParkingPhotoList = async (parkingId: number, districtId: number) => {
	return fetchEntityList(`SeznamFotPar/${districtId}/${parkingId}`, mapApiToPhoto);
};

export const uploadParkingPhoto = async (file: FileUploadDto, parkingId: number, districtId: number) => {
	return fetchUploadFile(file, {
		case: "PAR",
		ParId: parkingId,
		uzemi: districtId,
	});
};

export const deleteParkingPhoto = async (file: FileDto, parkingId: number, districtId: number) => {
	return fetchDeleteFile("deleteFilePAR", file, {
		ParId: parkingId,
		uzemi: districtId,
	});
};


//diaries
export const getParkingDiaryEntryList = (parkingId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniPAR/${districtId}/${parkingId}`, mapApiToDiaryEntry);
};


export const createParkingDiaryEntry = async (diaryEntry: DiaryEntryDto, parkingId: number, districtId: number) => {
	return fetchCreateEntity("insertHlaseniPAR", {
		...mapDiaryEntryToApi(diaryEntry, true),
		par_id: parkingId,
		IXopraveno: "0",
	});
};

export const getParkingDiaryEntryDetail = async (diaryEntryId: number, parkingId: number, districtId: number) => {
	return fetchEntityDetail(`DetailHlasenPAR/${districtId}/${parkingId}/${diaryEntryId}`, mapApiToDiaryEntry);
};

export const updateParkingDiaryEntry = async (diaryEntry: DiaryEntryDto, parkingId: number, districtId: number) => {
	return fetchUpdateEntity("updateHlaseniPAR", {
		...mapDiaryEntryToApi(diaryEntry, false),
		hlaseni_id: diaryEntry.id,
		par_id: parkingId,
		uzemi_id: districtId,
	});
};

export const deleteParkingDiaryEntry = async (diaryEntryId: number, parkingId: number, districtId: number) => {
	return fetchDeleteEntity("deleteHlaseniPAR", {
		hlaseniId: diaryEntryId,
		par_id: parkingId,
		uzemi: districtId,
	});
};

export const getParkingDiaryEntryRelatedList = (parentDiaryId: number, parkingId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniPARdt/${districtId}/${parkingId}/${parentDiaryId}`, mapApiToDiaryEntry);
};


//diary photos

export const getParkingDiaryPhotoList = async (diaryEntryId: number, parkingId: number, districtId: number) => {
	return fetchEntityList(`SeznamFotHlasPAR/${districtId}/${parkingId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadParkingDiaryPhoto = async (file: FileUploadDto, diaryEntryId: number) => {
	return fetchUploadFile(file, {
		case: "HlaseniPAR",
		hlaseni_id: diaryEntryId,
	});
};

export const deleteParkingDiaryPhoto = async (file: FileDto, diaryEntryId: number, parkingId: number, districtId: number) => {
	return fetchDeleteFile("deleteFileHlaseniPAR", file, {
		hlaseni_id: diaryEntryId,
		par_id: parkingId,
		uzemi: districtId,
	});
};


//all diaries
export const getAllParkingDiaryEntryList = async (): Promise<DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForParking>[]> => {
	return fetchEntityList(`SeznamHlaseniPARlau`, (response: DiaryEntryAdvancedParkingApiDto) => mapApiToDiaryEntryAdvanced(response,
		(data) => ({
			uzemiId: apiToNumber(data.uzemi_id),
			uzemiNazev: apiToString(data.nazev_ku),
			parkovisteId: apiToNumber(data.parkoviste_id),
			parkovisteOznaceni: apiToString(data.oznaceni_par),
			komunikaceOznaceni: apiToString(data.oznaceni_mk),
		}),
	));
};

//map
export const getMapParkingList = async (districtId: number) => {
	const list: MapParkingApiDto[] = await fetchToJson(`MapaPAR`)
		.then((r) => {
			return r.data;
		});
	const result = list.map((item) => mapApiToMapParkingDto(item));
	return result;
};


//position
export const updateParkingPosition = (geometry: LocationPoint[][] | null, parkingId: number, districtId: number) => {
	return fetchUpdateEntity("updateSPIPAR", {
		grafika: "Polygon",
		geom: geometryToApi(geometry),
		ParId: parkingId,
	});
};


//helpers
export const getAllParkingNamesInLau = async (): Promise<string[]> => {
	return fetchEntityList(`SeznamParLau`, mapApiToParkingNameDto); //FIXME: there is not
};


export const getParkingListForRoad = async (districtId: number, road: RoadDto): Promise<ParkingDto[]> => {
	return fetchEntityList(`SeznamPAR/${road.id}`, mapApiToParkingDto);
};
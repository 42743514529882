import { BlobWithFilename, TabContent, Spacing, useFetchBlob } from "@pasport/react-common";
import { DetailRow, DetailColumn, DetailCard, Button, LoadingIcon, DownloadIcon } from "@pasport/react-ui";
import {ReactNode} from "react";


interface DownloadTableTileProps {
    cardTitle: string,
    buttonLabel: string,
    onDownload: () => Promise<BlobWithFilename>;
    description?: ReactNode;
}

export const DownloadTableTile = ({cardTitle, buttonLabel, onDownload, description}: DownloadTableTileProps) => {
    const {requestOpen, isLoading} = useFetchBlob(onDownload);
    return (
        <TabContent>
            <DetailRow>
                <DetailColumn>
                    <DetailCard title={cardTitle}>
                        <div style={{paddingBottom: 10}}>
                            <Button
                                color='dark'
                                icon={isLoading ? <LoadingIcon variant='small' version='dark'/> : <DownloadIcon/>}
                                onPress={requestOpen}
                            >
                                {buttonLabel}
                            </Button>
                            {Boolean(description) && (
                                <>
                                    <Spacing space={3}/>
                                    <div className='small text-muted'>
                                        {description}
                                    </div>
                                </>
                            )}
                        </div>
                    </DetailCard>
                </DetailColumn>
            </DetailRow>
        </TabContent>
    )
}
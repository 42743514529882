import {RoadDto} from "../../interfaces";
import {getBridgeListForRoad} from "@apis/Bridge";
import {BridgeIcon} from "./BridgeIcon";
import {useApiStateEffect, SkeletonLoader, PluralMessage, TabEmptyContent} from "@pasport/react-common";
import {Modal, ListItem, Stack, RowItem} from "@pasport/react-ui";
import {getAbsoluteRoutePath} from "../../core/routing";

interface BridgeSearchModalProps {
    visible: boolean,
    onClose: () => void,
    districtId: number,
    road: RoadDto,
}


export const BridgeSearchModal = ({visible, onClose, road, districtId}: BridgeSearchModalProps) => {

    const [apiState] = useApiStateEffect(() => getBridgeListForRoad(districtId, road), [road.id, districtId]);


    return (
        <Modal show={visible} onClose={onClose} colorVariant='light' align={'top'}
               header={(
                   <>
                       <div className="fs-5 fw-bold">Mostní objekty komunikace {road.oznaceni}</div>
                   </>
               )}
        >

            <div className="overflsow-auto">
                <SkeletonLoader
                    data={[apiState]}
                    render={([bridgeList]) => (
                        <div className={"d-flex flex-column gap-3"}>
                            {bridgeList.length > 0 ? (
                                <div>
                                    <div className="fs-6 p-1 pt-0">
                                        <PluralMessage
                                            count={bridgeList.length}
                                            one="Celkem 1 mostní objekt"
                                            few="Celkem {count} mostní objekty"
                                            many="Celkem {count} mostních objektů"
                                        />
                                    </div>
                                    <div className={"d-flex flex-column gap-2"}>
                                        {bridgeList.map(bridge => (

                                            <ListItem
                                                link={getAbsoluteRoutePath('most', {
                                                    uzemiId: bridge.uzemiId ?? districtId,
                                                    mostId: bridge.id
                                                })}
                                                icon={
                                                    <BridgeIcon
                                                        className='fs-3'
                                                        style={{width: 30}}
                                                    />
                                                }
                                            >
                                                <Stack direction='row' spaceBetween center>

                                                    <RowItem
                                                        description={bridge.nazev}
                                                        size={5}
                                                        priority={10}
                                                    >
                                                        {bridge.oznaceni}
                                                    </RowItem>
                                                    <div className={`px-1`}>
                                                        <div className="small">{bridge.druh}</div>
                                                        <div className="small">{bridge.typ}</div>
                                                    </div>
                                                </Stack>
                                            </ListItem>
                                        ))}
                                    </div>


                                </div>) : (
                                <TabEmptyContent
                                    text={(
                                        <div>
                                            Komunikace <strong>{road.oznaceni}</strong> neobsahuje žádné mostní objekty.
                                        </div>
                                    )}
                                />
                            )}

                        </div>
                    )}
                    renderLoading={() => <TabEmptyContent text={"..."}/>}
                />

            </div>

        </Modal>


    )
}
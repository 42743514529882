import { createParkingDiaryEntry, getParkingDiaryEntryList } from "@apis/Parking";
import { ParkingFormEditable } from "../components/Parking/ParkingFormEditable";
import { ParkingDto } from "../interfaces/ParkingDto";
import { DiaryEntryCreateForm, DiaryList } from "@pasport/react-common";
import { useNavigate } from "react-router-dom";
import { useAlert, useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent } from "@pasport/react-common";
import { DetailIcon, AddIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface ParkingScreenProps {
	detail: ParkingDto;
}

export const ParkingScreen = ({ detail }: ParkingScreenProps) => {
	const { uzemiId, parkovisteId } = useUrlParams();
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();

	const [diaryEntriesApiState, invalidateDiaryEntries] = useApiStateEffect(() => getParkingDiaryEntryList(parkovisteId, uzemiId));

	return (
		<Page>

			<SkeletonLoader
				data={[diaryEntriesApiState]}
				render={([diaryEntries]) => (
					<>
						<PageHeader title={`Parkoviště ${detail.oznaceni}`} />
						<TabRoutes
							links={[{
								to: "detail",
								label: "Detail parkoviště",
								icon: <DetailIcon />,
								content: (
									<TabContent title={"O parkovišti"}>
										<ParkingFormEditable detail={detail} districtId={uzemiId} />
									</TabContent>
								),
							}, {
								to: "denik",
								label: "Deník parkoviště",
								icon: <DetailIcon />,
								content: (
									<DiaryList
										items={diaryEntries}
										getLink={(diary) => getAbsoluteRoutePath("parkovisteHlaseni", {
											uzemiId,
											parkovisteId,
											parkovisteHlaseniId: diary.id,
										})}

									/>
								),
							}]}
							actionLinks={[{
								to: "pridat-denik",
								label: "Přidat deníkový záznam",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat deníkový záznam"}>
										<DiaryEntryCreateForm
											dispatchCreate={(diaryEntry) => createParkingDiaryEntry(diaryEntry, parkovisteId, uzemiId)}
											onCreateSuccess={(diaryEntryId: number) => {
												addSuccessAlert({ message: "K parkovišti bylo přidáno deníkové hlášení." });
												navigate(
													getAbsoluteRoutePath("parkovisteHlaseni", {
														uzemiId,
														parkovisteId,
														parkovisteHlaseniId: diaryEntryId,
													}),
												);
												invalidateDiaryEntries();
											}}
										/>
									</TabContent>
								),
							}]}
						/>
					</>
				)}
			/>
		</Page>
	);


};
import {formatDate} from "@pasport/react-common";
import {ListItem, Icon, Row, RowItem} from "@pasport/react-ui";
import {TownDistrictDto} from "../../interfaces";
import {getAbsoluteRoutePath} from "../../core/routing";


interface TownDistrictItemProps {
    district: TownDistrictDto,
}


interface DataItemProps {
    label: string,
    value: string
}

const formatDateNullable = (value: Date | null) => {
    return value ? formatDate(value) : '—';
}

const DataItem = ({label, value}: DataItemProps) => {
    const isViewMode = true
    const key = "sadf";
    return <div className="d-flex flex-column">
        {isViewMode && <small className="lh-sm">{label}</small>}
        {!isViewMode && (
            <div className="d-flex justify-content-between">
                <label htmlFor={key}
                       className="form-label mb-1">
                    <small>{label}</small>
                </label>
            </div>
        )}
        <div className="fw-bold">{value ? value : '—'}</div>
    </div>
}

export const TownDistrictItem = ({district}: TownDistrictItemProps) => {
    const link = getAbsoluteRoutePath('uzemi', {uzemiId: district.id});
    return (
        <ListItem link={link}
                  icon={<span className={"fs-2"}><Icon type={'bi-geo-alt-fill'}/></span>/*FIXME size and icon fix*/}>
            <Row>
                <RowItem label={""} size={4.5} priority={10}>{district.nazev}</RowItem>
                <RowItem label={"Délka místních komunikací"} size={2.5}
                         priority={10}>
                    {district.delkaKomunikace} m </RowItem>
                <RowItem label={"Počet mostních objektů"} size={2.5}>{district.pocetMostnichObjektu}</RowItem>
                <RowItem label={"Počet parkovišť"} size={2.5}
                         priority={9}>{district.pocetParkovist}</RowItem>
                {/*FIXME its not 12 size*/}
            </Row>
        </ListItem>
    );
}

import {Route, Routes} from "react-router-dom";
import React from "react";
import {TownDistrictScreen} from "../screens/TownDistrictScreen";
import {useTownDistrict} from "../components/TownDistrict/useTownDistrict";
import {hasLauTownDistricts, useActiveLau} from "@pasport/react-common";
import {RoadRouter} from "./RoadRouter";
import {BridgeRouter} from "./BridgeRouter";
import {ParkingRouter} from "./ParkingRouter";
import {TownDistrictDto} from "../interfaces";
import {useRoadNames} from "../hooks/useRoadNames";
import {useParkingNames} from "../hooks/useParkingNames";
import {useBridgeNames} from "../hooks/useBridgeNames";
import {SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


export const TownDistrictRouter = () => {
    const {uzemiId} = useUrlParams()
    const {getTownDistrictDetail} = useTownDistrict();
    const {activeLau} = useActiveLau();
    const {roadNameListApiState} = useRoadNames();
    const {bridgeNameListApiState} = useBridgeNames();
    const {parkingNameListApiState} = useParkingNames();


    return <SkeletonLoader
        data={[getTownDistrictDetail(uzemiId), roadNameListApiState, bridgeNameListApiState, parkingNameListApiState]}
        render={([districtDetail, roadNames]: [TownDistrictDto, string[], string[], string[]]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('uzemi', {uzemiId})}
                        label={
                            hasLauTownDistricts(activeLau) ?
                                `Městská část ${districtDetail?.nazev}` :
                                `Katastrální území ${districtDetail?.nazev}`
                        }
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <TownDistrictScreen detail={districtDetail}/>
                        }/>
                        <Route path={RouterPaths.prehledMistniKomunikace + "/*"} element={
                            <RoadRouter detail={districtDetail}/>
                        }/>

                        <Route path={RouterPaths.prehledMostu + "/*"} element={
                            <BridgeRouter detail={districtDetail}/>
                        }/>
                        <Route path={RouterPaths.prehledParkovist + "/*"} element={
                            <ParkingRouter detail={districtDetail}/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
import {
	DiaryEntryAdvancedDetailForRoad,
} from "../interfaces";
import { getAllRoadDiaryEntryList } from "@apis/Road";
import { getAllBridgeDiaryEntryList } from "@apis/Bridge";
import { getAllParkingDiaryEntryList } from "@apis/Parking";
import { RoadAllDiaryList } from "../components/Road/RoadAllDiaryList";
import { BridgeAllDiaryList } from "../components/Bridge/BridgeAllDiaryList";
import { DiaryEntryAdvancedDetailForBridge } from "../interfaces/BridgeDto";
import { ParkingAllDiaryList } from "../components/Parking/ParkingAllDiaryList";
import { DiaryEntryAdvancedDetailForParking } from "../interfaces/ParkingDto";
import {
	useApiStateEffect,
	SkeletonLoader,
	BreadcrumbSetter,
	PageHeader,
	TabRoutes,
	DiaryEntryAdvancedDto,
} from "@pasport/react-common";
import { DiaryIcon } from "@pasport/react-ui";
import { Page } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../core/routing";


export const AllDiaryEntryListScreen = () => {

	const [roadDiaryListApiState] = useApiStateEffect(() => getAllRoadDiaryEntryList());
	const [bridgeDiaryListApiState] = useApiStateEffect(() => getAllBridgeDiaryEntryList());
	const [parkingDiaryListApiState] = useApiStateEffect(() => getAllParkingDiaryEntryList());


	return (
		<Page>
			<SkeletonLoader
				data={[roadDiaryListApiState, bridgeDiaryListApiState, parkingDiaryListApiState]}
				render={([roadDiaryList, bridgeDiaryList, parkingDiaryList]: [DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForRoad>[], DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForBridge>[], DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForParking>[]]) => (
					<>
						<BreadcrumbSetter
							to={getAbsoluteRoutePath("vsechnaHlaseni", {})}
							label={`Poslední hlášení v obci`}
						/>
						<PageHeader title={"Poslední deníková hlášení v obci"} />
						<TabRoutes links={[
							{
								to: "mistni-komunikace",
								label: "Seznam hlášení k místním komunikacím v obci",
								icon: <DiaryIcon />,
								content: <RoadAllDiaryList items={roadDiaryList} />,
							}, {
								to: "mostni-objekty",
								label: "Seznam hlášení k mostním objektům v obci",
								icon: <DiaryIcon />,
								content: <BridgeAllDiaryList items={bridgeDiaryList} />,
							}, {
								to: "parkoviste",
								label: "Seznam hlášení k parkovištím v obci",
								icon: <DiaryIcon />,
								content: <ParkingAllDiaryList items={parkingDiaryList} />,
							},
						]}
						/>
					</>
				)} />

		</Page>
	);
};
import {LocationPoint} from "maps-api/lib/core/LocationPoint"
import {BridgePrekazka, BridgeStav} from "../consts/Bridge";

export enum BridgeDruh {
    MOST = "most",
    PROPUSTEK = "propustek"
}

export enum BridgeTyp {
    SILNICNI = "silniční",
    LAVKA = "lávka pro pěší a cyklisty",
    SDRUZENY = "sdružený",
    NEZJISTENO = "nezjištěno",
    PROPUSTEK = "-"
}


export interface BridgeDto {
    id: number,
    oznaceni: string,
    nazev: string,
    druh: BridgeDruh,
    typ: BridgeTyp,
    komunikace: string,
    konstrukce: string,
    vlastnik: string,
    prekazka: BridgePrekazka,
    stav: BridgeStav,
    location: LocationPoint | null,
    sirka: number | null,
    vyska: number | null,
    datumVystavby: Date | null,
    datumMapovani: Date | null,
    datumAktualizace: Date | null,
    datumPosledniZavady: Date | null,
    datumPosledniUdrzby: Date | null,
    poznamka: string,
    dalsiInfo: string,

    pozice?: {
        x: number,
        y: number
    },
    uzemiId?: number,
}


export type MapBridgeDto = {
    id: number,
    oznaceni: string,
    nazev: string,
    druh: BridgeDruh,
    typ: BridgeTyp,
    komunikace: string,
    uzemiId: number,
    uzemiNazev: string,
    location: LocationPoint,
}


export type DiaryEntryAdvancedDetailForBridge = {
    uzemiId: number,
    uzemiNazev: string,
    mostId: number,
    mostOznaceni: string,
    komunikaceOznaceni: string,

}
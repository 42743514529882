import {RoadDto, RoadTyp} from "../../interfaces";
import {useRoadNames} from "../../hooks/useRoadNames";
import {
    ROAD_POVRCH_LIST,
    ROAD_PROVOZ_LIST,
    ROAD_TRIDA_LIST,
    ROAD_TYPE_LIST,
    ROAD_UMISTENI_LIST,
    ROAD_USEK_LIST
} from "../../consts/Road";
import {useField} from "formik";
import {validateIsNameUnique} from "../../common/validName.utils";
import {
    createForm,
    FormMode,
    FormVersion,
    FileDto,
    PositionCard,
    FileUploadDto,
    PhotoListCard
} from "@pasport/react-common";
import {DetailRow, DetailColumn, DetailCard, DetailItemRow, DetailEmptyItem, Button} from "@pasport/react-ui";

const Form = createForm<RoadDto>();

interface RoadCardsProps {
    mode: FormMode,
    version: FormVersion,
    photoApi?: {
        upload: (upload: FileUploadDto) => Promise<any>,
        delete: (file: FileDto) => Promise<any>,
        getList: () => Promise<FileDto[]>,
    },
    previousRoadName?: string,
    bridgeListUrl?: string,
    onBridgeListPress?: () => void,
    parkingListUrl?: string,
    onParkingListPress?: () => void,

}

export const RoadCards = ({
                              mode,
                              version,
                              photoApi,
                              previousRoadName,
                              bridgeListUrl,
                              onBridgeListPress,
                              parkingListUrl,
                              onParkingListPress
                          }: RoadCardsProps) => {

    const [typField] = useField<RoadTyp>("typ");
    const {roadNames} = useRoadNames();

    const validateName = (value: string) => {
        return validateIsNameUnique(value, roadNames, previousRoadName)
    }


    return (
        <DetailRow>
            <DetailColumn>
                <DetailCard title="Základní informace">
                    <div>
                        <DetailItemRow>
                            <Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required
                                           validate={validateName}/>
                        </DetailItemRow>
                        <DetailItemRow>
                            <Form.TextItem name={"nazev"} label={"Název"} mode={mode}/>

                        </DetailItemRow>
                        <DetailItemRow>
                            <Form.EnumItem name={"typ"} label={"Typ"} mode={mode} options={ROAD_TYPE_LIST} nullable
                                           required/>
                            {typField.value ? (
                                typField.value === RoadTyp.VOZOVKA ? (
                                    <Form.EnumItem name={"provoz"} label={"Provoz"} mode={mode}
                                                   options={ROAD_PROVOZ_LIST}/>
                                ) : (
                                    <Form.EnumItem name={"umisteni"} label={"Umístění"} mode={mode}
                                                   options={ROAD_UMISTENI_LIST} nullable/>
                                )
                            ) : <DetailEmptyItem/>}
                        </DetailItemRow>


                        <DetailItemRow>
                            <Form.EnumItem name={"trida"} label={"Třída"} mode={mode} options={ROAD_TRIDA_LIST}
                                           nullable required/>

                        </DetailItemRow>


                        <DetailItemRow>

                            <Form.NumberItem name={"pocetPruhu"} label={"Počet pruhů"} mode={mode}/>
                            <Form.EnumItem name={"usek"} label={"Úsek"} mode={mode} required options={ROAD_USEK_LIST}
                                           nullable/>
                        </DetailItemRow>


                        <Form.TextItem name={"vlastnik"} label={"Vlastník komunikace"} mode={mode}/>{/*TODO enum*/}


                        <Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode}/>
                    </div>
                </DetailCard>
                <DetailCard title="Data">
                    <div>
                        <DetailItemRow>
                            <Form.DateItem name={"datumVystavby"} label={"Datum výstavby"} mode={mode}/>
                            <Form.DateItem name={"datumMapovani"} label={"Datum mapování"} mode={mode}/>
                            <Form.DateItem name={"datumAktualizace"} label={"Datum aktualizace"} mode={mode}/>
                        </DetailItemRow>
                        <DetailItemRow>
                            <Form.DateItem name={"datumPosledniZavady"} label={"Datum poslední hlášené závady"}
                                           mode={mode} readonly/>
                            <Form.DateItem name={"datumPosledniUdrzby"} label={"Datum poslední údržby"} mode={mode}
                                           readonly/>
                        </DetailItemRow>

                    </div>
                </DetailCard>

                {photoApi && (
                    <PhotoListCard
                        hide={version === FormVersion.CREATE}
                        isEditMode={mode === FormMode.EDIT}
                        dispatchPhotoUpload={photoApi.upload}
                        loadImages={photoApi.getList}
                        onPhotoDelete={photoApi.delete}
                    />
                )}
            </DetailColumn>
            <DetailColumn>
                <DetailCard title="Vlastnosti">

                    <DetailItemRow>
                        <Form.NumberItem name={"delka"} label={"Délka"} mode={mode} unit={'m'} floatNumber/>

                        <Form.NumberItem
                            name={"pocetMostichObjektu"}
                            label={"Počet mostních objektů"}
                            mode={mode}
                            readonly
                            actions={
                                bridgeListUrl || onBridgeListPress ? (
                                    <a href={bridgeListUrl} target='_blank'>
                                        <Button
                                            color='dark'
                                            variant='outlined'
                                            size='small'
                                            onPress={onBridgeListPress}
                                        >Zobrazit</Button>
                                    </a>
                                ) : undefined
                            }
                            actionMode='view'

                        />
                    </DetailItemRow>

                    <DetailItemRow>

                        <Form.NumberItem name={"plocha"} label={"Plocha"} mode={mode} readonly unit={'m²'} floatNumber/>
                        <Form.NumberItem
                            name={"pocetParkovist"}
                            label={"Počet parkovišť"}
                            mode={mode}
                            readonly
                            actions={
                                parkingListUrl || onParkingListPress ? (
                                    <a href={parkingListUrl} target='_blank'>
                                        <Button
                                            color='dark'
                                            variant='outlined'
                                            size='small'
                                            onPress={onParkingListPress}
                                        >Zobrazit</Button>
                                    </a>
                                ) : undefined
                            }
                            actionMode='view'
                        />
                    </DetailItemRow>

                    <DetailItemRow>
                    </DetailItemRow>

                    <DetailItemRow>
                        <Form.NumberItem name={"prumernaSirka"} label={"Průměrná šířka"} mode={mode} floatNumber
                                         unit='m'/>
                    </DetailItemRow>


                    <DetailItemRow>
                        <Form.TextItem name={"stav"} label={"Stav"} mode={mode}/>
                        <Form.EnumItem name={"povrch"} label={"Převažující povrch"} mode={mode}
                                       options={ROAD_POVRCH_LIST}/>
                    </DetailItemRow>
                    <Form.TextItem name={"zimniUdrzba"} label={"Zimní údržba"} mode={mode}/>

                    <Form.TextareaItem name={"dalsiInformace"} label={"Další informace"} mode={mode}/>
                </DetailCard>


                <DetailCard title="Parcely">

                    <Form.TextareaItem name={"parcely"} label={"Parcely"} mode={mode}/>
                    <Form.TextareaItem name={"vlastniciParcel"} label={"Vlastníci parcel"} mode={mode}/>
                </DetailCard>

                <PositionCard objectType={'polygon'} mode={mode} version={version}
                              name={'geometry'}/>
            </DetailColumn>
        </DetailRow>
    )
}

import {getAllBridgeNamesInLau} from "@apis/Bridge";
import {useReduxApiStateEffect} from "../redux";

export const useBridgeNames = () => {
    const [bridgeNameListApiState, invalidateBridgeNames] = useReduxApiStateEffect("bridge-names", () => getAllBridgeNamesInLau());/*probably add activeLau deps*/

    return {
        bridgeNameListApiState,
        bridgeNames: (bridgeNameListApiState.data ?? []) as string[],
        invalidateBridgeNames,
    }
}
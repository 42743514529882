import {getAllRoadNamesInLau} from "@apis/Road";
import {useReduxApiStateEffect} from "../redux";

export const useRoadNames = () => {
    const [roadNameListApiState, invalidateRoadNames] = useReduxApiStateEffect("road-names", () => getAllRoadNamesInLau());/*probably add activeLau deps*/

    return {
        roadNameListApiState,
        roadNames: (roadNameListApiState.data ?? []) as string[],
        invalidateRoadNames,
    }
}
import { MapRoadDto, RoadDto } from "../../../interfaces";
import RoadMarkerPopup from "../popups/RoadMarkerPopup";
import { RoadIcon } from "../../Road/RoadIcon";
import { getRoadColor } from "../../../hooks/map/useMapRoads";
import MapMarker from "./MapMarker";
import { MapObject, Marker } from "@pasport/react-common";

interface RoadMarkerProps {
	road: MapRoadDto,
	map: MapObject,
	marker: Marker
}

export default function RoadMarker({
									   road, map, marker,
								   }: RoadMarkerProps) {

	return <MapMarker
		map={map}
		marker={marker}
		icon={<RoadIcon />}
		popup={<RoadMarkerPopup data={road} marker={marker} />}
		color={getRoadColor(road)}
		title={road.oznaceni}
		thresholdZoom={12} />;
}

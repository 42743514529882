import {
	LayerSwitchControl,
	LegendLayerLine,
	PanoramaCircleMarker,
	MarkerLayer,
	MapObject,

} from "@pasport/react-common";
import { CardDivider } from "@pasport/react-ui";
import { BridgeIcon } from "../../../components/Bridge/BridgeIcon";
import { ParkingIcon } from "../../../components/Parking/ParkingIcon";
import { RoadIcon } from "../../../components/Road/RoadIcon";
import { getBridgeColor } from "../../../hooks/map/useMapBridges";
import { getParkingColor } from "../../../hooks/map/useMapParkings";
import { getRoadColor } from "../../../hooks/map/useMapRoads";
import { MapRoadDto, RoadTrida, RoadTyp } from "../../../interfaces";
import { BridgeDruh, MapBridgeDto } from "../../../interfaces/BridgeDto";
import { MapParkingDto } from "../../../interfaces/ParkingDto";
import ShowTitlesButton from "./ShowTitlesButton";

export type LegendProps = {
	map: MapObject;
	roadsLayer?: MarkerLayer;
	roadsSubLayers?: MarkerLayer[];
	roadsTridaLayers?: MarkerLayer[];
	bridgesLayer?: MarkerLayer;
	bridgesSublayers?: MarkerLayer[];
	parkingsLayer?: MarkerLayer;
	panoramasLayer?: MarkerLayer;
	showPanoramaLayer?: boolean;
};

export default function Legend({
								   map,
								   roadsLayer,
								   roadsSubLayers,
								   roadsTridaLayers,
								   bridgesLayer,
								   bridgesSublayers,
								   parkingsLayer,
								   panoramasLayer,
								   showPanoramaLayer,
							   }: LegendProps) {
	return (
		<>
			<LegendLayerLine
				title="Komunikace"
				layer={roadsLayer}
				icon={<RoadIcon />}
				render={() => (
					<>
						<LegendLayerLine
							title="Vozovka"
							layer={roadsSubLayers?.[0]}
							level={1}
							render={() => (
								<>
									<LegendLayerLine
										title={RoadTrida.TRIDA_1}
										layer={roadsTridaLayers?.[0]}
										color={getRoadColor({
											trida: RoadTrida.TRIDA_1,
											typ: RoadTyp.VOZOVKA,
										} as MapRoadDto)}
										level={2}
									/>
									<LegendLayerLine
										title={RoadTrida.TRIDA_2}
										layer={roadsTridaLayers?.[1]}
										color={getRoadColor({
											trida: RoadTrida.TRIDA_2,
											typ: RoadTyp.VOZOVKA,
										} as MapRoadDto)}
										level={2}
									/>
									<LegendLayerLine
										title={RoadTrida.TRIDA_3}
										layer={roadsTridaLayers?.[2]}
										color={getRoadColor({
											trida: RoadTrida.TRIDA_3,
											typ: RoadTyp.VOZOVKA,
										} as MapRoadDto)}
										level={2}
									/>
									<LegendLayerLine
										title={RoadTrida.TRIDA_4}
										layer={roadsTridaLayers?.[3]}
										color={getRoadColor({
											trida: RoadTrida.TRIDA_4,
											typ: RoadTyp.VOZOVKA,
										} as MapRoadDto)}
										level={2}
									/>
									<LegendLayerLine
										title={RoadTrida.UCELOVA_KOMUNIKACE}
										color={getRoadColor({
											trida: RoadTrida.UCELOVA_KOMUNIKACE,
											typ: RoadTyp.VOZOVKA,
										} as MapRoadDto)}
										layer={roadsTridaLayers?.[4]}
										level={2}
									/>
								</>
							)}
						/>
						<LegendLayerLine title="Chodník" layer={roadsSubLayers?.[1]}
										 color={getRoadColor({ typ: RoadTyp.CHODNIK } as MapRoadDto)} level={1} />
						<LegendLayerLine title="Cyklostezka" layer={roadsSubLayers?.[2]}
										 color={getRoadColor({ typ: RoadTyp.CYKLOSTEZKA } as MapRoadDto)} level={1} />
						<LegendLayerLine title="Chodník + Cyklostezka" layer={roadsSubLayers?.[3]}
										 color={getRoadColor({ typ: RoadTyp.CHODNIK_CYKLOSTEZKA } as MapRoadDto)}
										 level={1} />
					</>
				)}
			/>
			<LegendLayerLine
				title="Mosty"
				layer={bridgesLayer}
				icon={<BridgeIcon />}
				render={() => (
					<>
						<LegendLayerLine title="Most" layer={bridgesSublayers?.[0]}
										 color={getBridgeColor({ druh: BridgeDruh.MOST } as MapBridgeDto)} level={1} />
						<LegendLayerLine title="Propustek" layer={bridgesSublayers?.[1]}
										 color={getBridgeColor({ druh: BridgeDruh.PROPUSTEK } as MapBridgeDto)}
										 level={1} />
					</>
				)}
			/>

			<LegendLayerLine title="Parkoviště" layer={parkingsLayer} color={getParkingColor({} as MapParkingDto)}
							 icon={<ParkingIcon />} />
			{showPanoramaLayer && (
				<LegendLayerLine
					title="Panoramata"
					layer={panoramasLayer}
					icon={
						<div
							style={{
								transform: "translate(50%, 50%)",
								height: "100%",
							}}
						>
							<PanoramaCircleMarker size={16} />
						</div>
					}
				/>
			)}

			<div className="m-2" />
			<CardDivider />
			<div className="m-2" />
			<ShowTitlesButton />

			<LayerSwitchControl map={map} />
		</>
	);
}

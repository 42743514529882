import {useNavigate} from "react-router-dom";
import {BridgeDruh, BridgeDto, BridgeTyp} from "../../interfaces/BridgeDto";
import {BridgeCards} from "./BridgeCards";
import {createBridge} from "@apis/Bridge";
import {useBridgeNames} from "../../hooks/useBridgeNames";
import {useAlert, FormWithCreate, FormMode, FormVersion} from "@pasport/react-common";
import {getAbsoluteRoutePath} from "../../core/routing";

const defaultBridge: Omit<BridgeDto, 'druh'> & { druh: BridgeDruh | null } = {
    id: 0,
    oznaceni: "",
    nazev: "",
    druh: null,
    typ: BridgeTyp.NEZJISTENO,
    komunikace: "",
    konstrukce: "",
    vlastnik: "",
    prekazka: null,
    stav: 'nezjištěno',
    location: [1, 1],//FIXME should not be required
    sirka: 0,
    vyska: 0,
    datumVystavby: null,
    datumMapovani: null,
    datumAktualizace: null,
    datumPosledniZavady: null,
    datumPosledniUdrzby: null,
    poznamka: "",
    dalsiInfo: "",

};


interface BridgeFormCreateProps {
    districtId: number,
    onCreate?: () => void,
}

export const BridgeFormCreate = ({districtId, onCreate}: BridgeFormCreateProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert()
    const {invalidateBridgeNames} = useBridgeNames();
    return (
        <FormWithCreate
            initValues={defaultBridge}
            dispatchCreate={(value) => createBridge(value as BridgeDto, districtId)}
            onCreateSuccess={(bridgeId: number) => {
                addSuccessAlert({message: "Byl vytvořen mostní objekt."});
                navigate(
                    getAbsoluteRoutePath('most', {
                        uzemiId: districtId,
                        mostId: bridgeId
                    })
                );
                invalidateBridgeNames();
                if (onCreate) onCreate();
            }}
        >
            {() => <BridgeCards mode={FormMode.EDIT} version={FormVersion.CREATE}/>}
        </FormWithCreate>
    )

}


import {Route, Routes} from "react-router-dom";
import React from "react";
import {TownDistrictRouter} from "./TownDistrictRouter";

import {MapScreen} from "../screens/MapScreen/MapScreen";
import {HomeScreen} from "../screens/HomeScreen";
import {AllDiaryEntryListScreen} from "../screens/AllDiaryEntryListScreen";
import {Protected, LoginCallbackScreen, SelectLauScreen} from "@pasport/react-common";
import {Breadcrumbs} from "@pasport/react-common";
import {RouterPaths} from "../core/routing";


export const AppRouter = () => {

    return (
        <div className={'h-100 d-flex flex-column'}>
            <Breadcrumbs/>
            <div className={'flex-grow-1'}>
                <Routes>
                    <Route path="*" element={
                        <Protected>
                            <HomeScreen/>
                        </Protected>
                    }/>

                    <Route path={RouterPaths.uzemi + "/*"} element={
                        <Protected>
                            <TownDistrictRouter/>
                        </Protected>
                    }/>
                    <Route path={RouterPaths.mapa + "/*"} element={
                        <Protected>
                            <MapScreen/>
                        </Protected>
                    }/>

                    <Route path={RouterPaths.vsechnaHlaseni + "/*"} element={
                        <Protected>
                            <AllDiaryEntryListScreen/>
                        </Protected>
                    }/>
                    <Route path={"vyber-lau"} element={(
                        <Protected>
                            <SelectLauScreen/>
                        </Protected>
                    )}/>
                    <Route path={"login"} element={<LoginCallbackScreen/>}/>
                </Routes>
            </div>
        </div>
    )
}
import { TownDistrictDto } from "../interfaces";
import { RoadList } from "../components/Road/RoadList";
import { downloadRoadListTable, getRoadList } from "@apis/Road/RoadApis";
import { RoadFormCreate } from "../components/Road/RoadFormCreate";
import { DownloadTableTile } from "../common/DownloadTableTile";
import { useApiStateEffect, SkeletonLoader, PageHeader, TabRoutes, TabContent, Page } from "@pasport/react-common";
import { ListIcon, AddIcon, DownloadIcon } from "@pasport/react-ui";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface RoadOverviewScreenProps {
	detail: TownDistrictDto;
}

export const RoadOverviewScreen = ({ detail }: RoadOverviewScreenProps) => {
	const { uzemiId } = useUrlParams();

	const [roadListApiState, invalidateRoadList] = useApiStateEffect(() => getRoadList(uzemiId));

	return (
		<SkeletonLoader
			data={[roadListApiState]}
			render={([roadList]) => (
				<Page>
					<PageHeader title={`Místní komunikace - ${detail.nazev}`} />
					<TabRoutes
						links={[
							{
								to: "seznam",
								label: "Seznam místních komunikací",
								icon: <ListIcon />,
								content: (
									<TabContent title={"Seznam místních komunikací"} isEmpty={roadList.length === 0} emptyLabel={"Zatím zde není žádná místní komunikace"}>
										<RoadList
											items={roadList}
											getLink={(road) =>
												getAbsoluteRoutePath("mistniKomunikace", {
													uzemiId,
													mistniKomunikaceId: road.id,
												})
											}
										/>
									</TabContent>
								),
							},
						]}
						actionLinks={[
							{
								to: "pridat-komunikaci",
								label: "Přidat místní komunikaci",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat místní komunikaci"}>
										<RoadFormCreate onCreate={invalidateRoadList} districtId={uzemiId} />
									</TabContent>
								),
							},
							{
								to: "exportovat",
								label: "Exportovat",
								icon: <DownloadIcon />,
								content: (
									<DownloadTableTile
										onDownload={() => downloadRoadListTable(detail)}
										cardTitle={"Seznam místních komunikací"}
										buttonLabel={"Stáhnout tabulku místních komunikací"}
										description={"Hvězdička (*) v tabulce označuje položky, které jsou stěžejní pro dotační žádost o obnovu místních komunikací."}
									/>
								),
							},
						]}
					/>
				</Page>
			)}
		/>
	);
};

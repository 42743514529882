import {TownDistrictApiDto} from "./TownDistrictApiDto";
import {TownDistrictDto} from "../../interfaces";
import { apiToNumber, apiToString } from "@pasport/react-common";

export const mapApiToTownDistrict = (response: TownDistrictApiDto): TownDistrictDto => {

    return {
        id: apiToNumber(response.uzemi_id),
        nazev: apiToString(response.nazev_ku),
        delkaKomunikace: apiToNumber(parseInt(response.delka_mk)),
        pocetMostnichObjektu: apiToNumber(response.pocet_mo),
        pocetParkovist: apiToNumber(response.pocet_par)
    }
}
import { Modal } from "@pasport/react-ui";
import {
    RoadFilterDispatch,
    RoadFilterState
} from "./roadFilter.utils";
import {RoadFilterModalContent} from "./RoadFilterModalContent";

interface RoadFilterModalProps {
    visible: boolean,
    onClose: () => void,
    dispatch: RoadFilterDispatch,
    filterState: RoadFilterState
}


export const RoadFilterModal = (props: RoadFilterModalProps) => {


    return (
        <Modal show={props.visible} onClose={props.onClose} colorVariant='light' align={'top'}
               header={(
                   <>
                       <div className="fs-5 fw-bold pb-2">Filtrovat místní komunikace</div>
                   </>
               )}

        >
            <RoadFilterModalContent {...props} />
        </Modal>
    );
}
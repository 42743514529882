import React, {useMemo} from "react";
import {
    DiaryEntryAdvancedDto,
    FulltextSearchOption,
    useActiveLau,
    withCommonDiaryOptions,
    DiaryListAdvanced, hasLauTownDistricts
} from "@pasport/react-common";
import {DiaryEntryAdvancedDetailForBridge} from "../../interfaces/BridgeDto";
import {getAbsoluteRoutePath} from "../../core/routing";


const getAdvancedDiaryLinkForBridge = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForBridge>) => {
    return getAbsoluteRoutePath('mostHlaseni', {
        uzemiId: diaryEntry.detail.uzemiId,
        mostId: diaryEntry.detail.mostId,
        mostHlaseniId: diaryEntry.value.id
    })
}

const getAdvancedDiaryLabelForBridge = (diaryEntry: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForBridge>) => {
    return (
        <>
            <strong>{diaryEntry.detail.uzemiNazev}</strong>
            {" - Mostní objekt "}<strong>{diaryEntry.detail.mostOznaceni}</strong>
            {"/"}<strong>{diaryEntry.detail.komunikaceOznaceni}</strong>
        </>
    )
}


interface BridgeAllDiaryListProps {
    items: DiaryEntryAdvancedDto<DiaryEntryAdvancedDetailForBridge>[]
}

export const BridgeAllDiaryList = ({items}: BridgeAllDiaryListProps) => {
    const {activeLau} = useActiveLau();

    const searchOptions = useMemo(() => {
        const hasDistricts = hasLauTownDistricts(activeLau)
        const detailOptions = {
            'DISTRICT': {
                option: hasDistricts ? "Městská část" : "Katastrální území",
                placeholder: hasDistricts ? "Hledat dle městské části ..." : 'Hledat dle katastrálního území ...',
                getSearchStrings: (diary) => {
                    return [diary.detail.uzemiNazev];
                }
            },
            'ROAD': {
                option: "Komunikace",
                placeholder: "Hledat dle označení komunikace ...",
                getSearchStrings: (diary) => {
                    return [diary.detail.komunikaceOznaceni || ''];
                }
            },
            'BRIDGE': {
                option: "Mostní objekt",
                placeholder: "Hledat dle označení mostního objektu ...",
                getSearchStrings: (diary) => {
                    return [diary.detail.mostOznaceni || ''];
                }
            },
        } as Record<string, FulltextSearchOption<DiaryEntryAdvancedDetailForBridge>>;

        return withCommonDiaryOptions(detailOptions);

    }, [activeLau])

    return (
        <DiaryListAdvanced
            items={items}
            getLink={getAdvancedDiaryLinkForBridge}
            getLabel={getAdvancedDiaryLabelForBridge}
            searchOptions={searchOptions}
        />
    )
}
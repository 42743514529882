import {BridgeDruh, BridgeDto} from "../../interfaces/BridgeDto";
import {validateIsNameUnique} from "../../common/validName.utils";
import {useBridgeNames} from "../../hooks/useBridgeNames";
import {useRoadNames} from "../../hooks/useRoadNames";
import {BRIDGE_DRUH_LIST, BRIDGE_PREKAZKA_LIST, BRIDGE_STAV_LIST, BRIDGE_TYP_LIST} from "../../consts/Bridge";
import {useField} from "formik";
import {
    createForm,
    FormMode,
    FormVersion,
    FileDto,
    PositionCard,
    FileUploadDto,
    PhotoListCard
} from "@pasport/react-common";
import {DetailRow, DetailColumn, DetailCard, Button, DetailItemRow} from "@pasport/react-ui";


const Form = createForm<BridgeDto>();

interface BridgeCardsProps {
    mode: FormMode,
    version: FormVersion,
    photoApi?: {
        upload: (upload: FileUploadDto) => Promise<any>,
        delete: (file: FileDto) => Promise<any>,
        getList: () => Promise<FileDto[]>,
    },
    previousBridgeName?: string
    onRoadDetailPress?: () => void,
}

export const BridgeCards = ({mode, version, photoApi, previousBridgeName, onRoadDetailPress}: BridgeCardsProps) => {

    const [druhField] = useField<BridgeDruh>("druh");

    const {bridgeNames} = useBridgeNames();
    const {roadNames} = useRoadNames();

    const validateName = (value: string) => {
        return validateIsNameUnique(value, bridgeNames, previousBridgeName)
    }
    return (
        <DetailRow>
            <DetailColumn>
                <DetailCard title="Základní informace">
                    <Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required validate={validateName}/>
                    <Form.TextItem name={"nazev"} label={"Název"} mode={mode}/>
                    <Form.EnumItem
                        name={"komunikace"}
                        label={"Komunikace"}
                        mode={mode}
                        options={roadNames}
                        nullable

                        actions={
                            onRoadDetailPress ? (
                                <Button
                                    color='dark'
                                    variant='outlined'
                                    size='small'
                                    onPress={onRoadDetailPress}
                                >Zobrazit detail komunikace</Button>
                            ) : undefined
                        }
                        actionMode='view'
                    />

                    <DetailItemRow>
                        <Form.EnumItem name={"stav"} label={"Stav"} mode={mode} options={BRIDGE_STAV_LIST} nullable/>
                        <Form.TextItem name={"vlastnik"} label={"Vlastník"} mode={mode}/>
                    </DetailItemRow>

                    <Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode}/>
                </DetailCard>
                {photoApi && (
                    <PhotoListCard
                        hide={version === FormVersion.CREATE}
                        isEditMode={mode === FormMode.EDIT}
                        dispatchPhotoUpload={photoApi.upload}
                        loadImages={photoApi.getList}
                        onPhotoDelete={photoApi.delete}
                    />
                )}
            </DetailColumn>
            <DetailColumn>

                <DetailCard title="Vlastnosti">

                    <DetailItemRow>
                        <Form.EnumItem name={"druh"} label={"Druh MO"} mode={mode} required
                                       options={BRIDGE_DRUH_LIST} nullable/> {/*TODO enum*/}
                        {druhField.value === BridgeDruh.MOST && (
                            <Form.EnumItem name={"typ"} label={"Typ mostu"} mode={mode}
                                           required options={BRIDGE_TYP_LIST} nullable/>
                        )}

                    </DetailItemRow>

                    <DetailItemRow>
                        <Form.EnumItem name={"prekazka"} label={"Překážka"}
                                       mode={mode} options={BRIDGE_PREKAZKA_LIST} nullable/>
                        <Form.TextItem name={"konstrukce"} label={"Konstrukce"} mode={mode}/>
                    </DetailItemRow>
                    <DetailItemRow>
                        <Form.NumberItem name={'sirka'} label={'Šířka'} mode={mode} unit='m' floatNumber/>
                        <Form.NumberItem name={'vyska'} label={'Výška'} mode={mode} unit='m' floatNumber/>
                    </DetailItemRow>
                    <Form.TextareaItem name={"dalsiInfo"} label={"Další informace"} mode={mode}/>
                </DetailCard>
                <DetailCard title="Data">
                    <DetailItemRow>
                        <Form.DateItem name={"datumVystavby"} label={"Datum výstavby"} mode={mode}/>
                        <Form.DateItem name={"datumMapovani"} label={"Datum mapování"} mode={mode}/>
                        <Form.DateItem name={"datumAktualizace"} label={"Datum aktualizace"} mode={mode}/>
                    </DetailItemRow>
                    <DetailItemRow>
                        <Form.DateItem name={"datumPosledniZavady"} label={"Datum poslední hlášené závady"}
                                       mode={mode} readonly/>
                        <Form.DateItem name={"datumPosledniUdrzby"} label={"Datum poslední údržby"} mode={mode}
                                       readonly/>
                    </DetailItemRow>
                </DetailCard>

                <PositionCard objectType={'point'} mode={mode} version={version}/>
            </DetailColumn>
        </DetailRow>
    )
}

import {ParkingDto} from "../../interfaces/ParkingDto";
import {validateIsNameUnique} from "../../common/validName.utils";
import {useParkingNames} from "../../hooks/useParkingNames";
import {useRoadNames} from "../../hooks/useRoadNames";
import {PARKING_PARKOVANI_LIST, PARKING_POVRCH_LIST} from "../../consts/Parking/parking.consts";
import {
    createForm,
    FormMode,
    FormVersion,
    FileDto,
    PositionCard,
    FileUploadDto,
    PhotoListCard
} from "@pasport/react-common";
import {DetailRow, DetailColumn, DetailCard, Button, DetailItemRow} from "@pasport/react-ui";


const Form = createForm<ParkingDto>();

interface RoadCardsProps {
    mode: FormMode,
    version: FormVersion,
    photoApi?: {
        upload?: (upload: FileUploadDto) => Promise<any>,
        delete?: (file: FileDto) => Promise<any>,
        getList?: () => Promise<FileDto[]>,
    },
    previousParkingName?: string,
    onRoadDetailPress?: () => void,
}

export const ParkingCards = ({mode, version, photoApi, previousParkingName, onRoadDetailPress}: RoadCardsProps) => {
    const {parkingNames} = useParkingNames();
    const {roadNames} = useRoadNames();

    const validateName = (value: string) => {
        return validateIsNameUnique(value, parkingNames, previousParkingName)
    }
    return (
        <DetailRow>
            <DetailColumn>
                <DetailCard title="Základní informace">
                    <Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required validate={validateName}/>
                    <Form.TextItem name={"nazev"} label={"Název"} mode={mode}/>
                    <Form.EnumItem
                        name={"komunikace"}
                        label={"Komunikace"}
                        mode={mode}
                        options={roadNames}
                        actions={
                            onRoadDetailPress ? (
                                <Button
                                    color='dark'
                                    variant='outlined'
                                    size='small'
                                    onPress={onRoadDetailPress}
                                >Zobrazit detail komunikace</Button>
                            ) : undefined
                        }
                        nullable
                        actionMode='view'


                    />

                    <DetailItemRow>
                        <Form.TextItem name={"stav"} label={"Stav"} mode={mode}/> {/*TODO enum?*/}
                        <Form.TextItem name={"vlastnik"} label={"Vlastník"} mode={mode}/>
                    </DetailItemRow>

                    <Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode}/>
                </DetailCard>
                {photoApi && (
                    <PhotoListCard
                        hide={version === FormVersion.CREATE}
                        isEditMode={mode === FormMode.EDIT}
                        dispatchPhotoUpload={photoApi.upload}
                        loadImages={photoApi.getList}
                        onPhotoDelete={photoApi.delete}
                    />
                )}
            </DetailColumn>
            <DetailColumn>


                <DetailCard title="Vlastnosti">
                    <Form.EnumItem name={"parkovani"} label={"Parkování"} mode={mode} options={PARKING_PARKOVANI_LIST}
                                   required/>
                    <Form.EnumItem name={"povrch"} label={"Převažující povrch"} mode={mode}
                                   options={PARKING_POVRCH_LIST}/>

                    <Form.NumberItem name={"plocha"} label={"Plocha"} unit={'m²'} mode={mode} readonly/>

                    <DetailItemRow>
                        <Form.NumberItem name={"pocetMist"} label={"Počet míst celkem"} mode={mode}/>
                        <Form.NumberItem name={"pocetMistProInvalidy"} label={"Počet míst pro invalidy"} mode={mode}/>
                        <Form.NumberItem name={"pocetVyhrazenychMist"} label={"Počet vyhrazených míst"} mode={mode}/>
                    </DetailItemRow>
                    <Form.TextareaItem name={"dalsiInfo"} label={"Další informace"} mode={mode}/>
                </DetailCard>
                <DetailCard title="Data">
                    <DetailItemRow>
                        <Form.DateItem name={"datumVystavby"} label={"Datum výstavby"} mode={mode}/>
                        <Form.DateItem name={"datumMapovani"} label={"Datum mapování"} mode={mode}/>
                        <Form.DateItem name={"datumAktualizace"} label={"Datum aktualizace"} mode={mode}/>
                    </DetailItemRow>
                    <DetailItemRow>
                        <Form.DateItem name={"datumPosledniZavady"} label={"Datum poslední hlášené závady"}
                                       mode={mode} readonly/>
                        <Form.DateItem name={"datumPosledniUdrzby"} label={"Datum poslední údržby"} mode={mode}
                                       readonly/>
                    </DetailItemRow>
                </DetailCard>

                <PositionCard objectType={'polygon'} mode={mode} version={version}
                              name={'geometry'}/>
            </DetailColumn>
        </DetailRow>
    )
}

import {useNavigate} from "react-router-dom";
import {
    RoadDto,
} from "../interfaces";
import React from "react";
import {DiaryParametricScreen} from "@pasport/react-common";
import {
    createRoadDiaryEntry,
    deleteRoadDiaryEntry, deleteRoadDiaryPhoto,
    getRoadDiaryEntryDetail,
    getRoadDiaryEntryRelatedList, getRoadDiaryPhotoList,
    updateRoadDiaryEntry, uploadRoadDiaryPhoto
} from "@apis/Road";
import {useAlert, useBreadcrumbs} from "@pasport/react-common";
import {getAbsoluteRoutePath, useUrlParams} from "../core/routing";

interface RoadDiaryScreenProps {
    detail: RoadDto
}

export const RoadDiaryScreen = ({detail}: RoadDiaryScreenProps) => {
    const navigate = useNavigate();
    const {addSuccessAlert} = useAlert();
    const {uzemiId, mistniKomunikaceId, mistniKomunikaceHlaseniId} = useUrlParams();

    useBreadcrumbs({
        to: getAbsoluteRoutePath('mistniKomunikaceHlaseni', {uzemiId, mistniKomunikaceId, mistniKomunikaceHlaseniId}),
        label: 'Deníkové hlášení'
    });

    return <DiaryParametricScreen
        identifier={mistniKomunikaceHlaseniId}
        title={`Deníkové hlášení místní komunikace ${detail.oznaceni}`}
        getLink={(diaryEntry) => getAbsoluteRoutePath('mistniKomunikaceHlaseni', {
            uzemiId,
            mistniKomunikaceId,
            mistniKomunikaceHlaseniId: diaryEntry.id
        })}

        dispatchDetail={() => getRoadDiaryEntryDetail(mistniKomunikaceHlaseniId, mistniKomunikaceId, uzemiId)}
        dispatchChildren={() => getRoadDiaryEntryRelatedList(mistniKomunikaceHlaseniId, mistniKomunikaceId, uzemiId)}

        dispatchUpdate={(diaryEntry) => updateRoadDiaryEntry(diaryEntry, mistniKomunikaceId, uzemiId)}
        onUpdateSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení mistní komunikace bylo upraveno."});
        }}

        dispatchDelete={() => deleteRoadDiaryEntry(mistniKomunikaceHlaseniId, mistniKomunikaceId, uzemiId)}
        onDeleteSuccess={() => {
            addSuccessAlert({message: "Deníkové hlášení místní komunikace bylo odstraněno."});
            navigate(
                getAbsoluteRoutePath('mistniKomunikace', {
                    uzemiId,
                    mistniKomunikaceId,
                })
            );
        }}

        dispatchCreate={(diaryEntry) => createRoadDiaryEntry({
            ...diaryEntry,
            parentId: mistniKomunikaceHlaseniId
        }, mistniKomunikaceId, uzemiId)}
        onCreateSuccess={(diaryEntryId) => {
            addSuccessAlert({message: "K závadě bylo přidáno hlášení."});
            navigate(
                getAbsoluteRoutePath('mistniKomunikaceHlaseni', {
                    uzemiId,
                    mistniKomunikaceId,
                    mistniKomunikaceHlaseniId: diaryEntryId
                })
            );
        }}


        dispatchPhotoList={() => getRoadDiaryPhotoList(mistniKomunikaceHlaseniId, mistniKomunikaceId, uzemiId)}
        dispatchPhotoUpload={(file) => uploadRoadDiaryPhoto(file, mistniKomunikaceHlaseniId)}
        dispatchPhotoDelete={(file) => deleteRoadDiaryPhoto(file, mistniKomunikaceHlaseniId, mistniKomunikaceId, uzemiId)}
    />
}
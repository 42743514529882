import {ParkingItem} from "./ParkingItem";
import {ParkingDto} from "../../interfaces/ParkingDto";
import {useParkingFilter} from "./ParkingFilter";
import {ParkingListFilter} from "./ParkingFilter/ParkingListFilter";
import { Spacing, ListContainer } from "@pasport/react-common";
import { Stack } from "@pasport/react-ui";


interface RoadListProps {
    items: ParkingDto[],
    getLink: (sign: ParkingDto) => string
}

export const ParkingList = ({items, getLink}: RoadListProps) => {
    const {filteredItems, ...restProps} = useParkingFilter(items);
    return <>
        <ParkingListFilter {...restProps} />
        <Spacing space={2}/>
        <ListContainer
            nextPage={100}
            items={filteredItems}
            render={(renderItems) => (
                <Stack space={2}>
                    {renderItems.map(parking => <ParkingItem value={parking} key={parking.id}
                                                             link={getLink(parking)}/>)}


                </Stack>
            )}
            emptyLabel={'Filtrům neodpovídá žádné parkoviště'}
        />

    </>
}
import {useState} from "react";
import {BRIDGE_SORT_OPTIONS, BRIDGE_SORT_OPTIONS_KEYS} from "./bridgeFilter.utils";
import {BridgeDto} from "../../../interfaces/BridgeDto";
import {SORT_DIRECTION, getFulltextSearch, sortBySortMethod, useUrlState} from "@pasport/react-common";


function getSortingFunc(key: BRIDGE_SORT_OPTIONS_KEYS, sortDirection: SORT_DIRECTION): (a: BridgeDto, b: BridgeDto) => number {
    const increasingOrder = sortDirection === SORT_DIRECTION.UP;
    const realKey = key in BRIDGE_SORT_OPTIONS ? key : 'OZNACENI';
    const func = BRIDGE_SORT_OPTIONS[realKey].method;
    return (a, b) => func(a, b, increasingOrder)
}


export const useBridgeFilter = (objects: BridgeDto[]) => {
    const [searchQuery, setSearchQuery] = useUrlState("search");
    const [sortMethod, setSortMethod] = useState<BRIDGE_SORT_OPTIONS_KEYS>('OZNACENI');
    const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);
    const [bridgeKind, setBridgeKind] = useState("all");

    const itemsFulltext = getFulltextSearch(searchQuery, objects, item => [
        item.oznaceni,
        item.nazev,
        item.komunikace
    ]);
    const filteredItemsA = sortBySortMethod(itemsFulltext, getSortingFunc(sortMethod, sortDirection))
    const filteredItems = filteredItemsA.filter(item => item.druh === bridgeKind || bridgeKind === "all");
    return {
        setSearchQuery,
        setSortMethod,
        setSortDirection,
        setBridgeKind,
        filteredItems,
        searchQuery
    }
}
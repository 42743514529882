import {Route, Routes} from "react-router-dom";
import React from "react";
import {BridgeScreen} from "../screens/BridgeScreen";
import {getBridgeDetail} from "@apis/Bridge";
import {BridgeDiaryScreen} from "../screens/BridgeDiaryScreen";
import {useApiStateEffect, SkeletonLoader, BreadcrumbSetter} from "@pasport/react-common";
import {getAbsoluteRoutePath, RouterPaths, useUrlParams} from "../core/routing";


interface BridgeDetailRouterProps {
}

export const BridgeDetailRouter = ({}: BridgeDetailRouterProps) => {
    const {uzemiId, mostId} = useUrlParams();
    const [detailApiState] = useApiStateEffect(() => getBridgeDetail(mostId, uzemiId));

    return <SkeletonLoader
        data={[detailApiState]}
        render={([detail]) => {
            return (
                <>
                    <BreadcrumbSetter
                        to={getAbsoluteRoutePath('most', {uzemiId, mostId})}
                        label={`Mostí objekt ${detail.oznaceni}`}
                    />
                    <Routes>
                        <Route path={"*"} element={
                            <BridgeScreen detail={detail}/>
                        }/>
                        <Route path={RouterPaths.mostHlaseni + "/*"} element={
                            <BridgeDiaryScreen detail={detail}/>
                        }/>

                    </Routes>
                </>
            )
        }}
    />
}
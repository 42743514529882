import {configureStore} from '@reduxjs/toolkit';
import {apiCallsReducer} from "./slices/apiCallsSlice";

export const store = configureStore({
    reducer: {
        apiCalls: apiCallsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
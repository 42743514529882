import { formatDate } from "@pasport/react-common";
import { ListItem, Row, RowItem } from "@pasport/react-ui";
import {ParkingDto} from "../../interfaces/ParkingDto";
import { ParkingIcon } from "./ParkingIcon";

interface ParkingItemProps {
    value: ParkingDto,
    link: string
}


export const ParkingItem = ({value, link}: ParkingItemProps) => {
    return (
        <ListItem link={link} icon={<ParkingIcon className='fs-3'/>}>
            <Row>
                <RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
                <RowItem label={"Název"} size={2}>{value.nazev}</RowItem>
                <RowItem label={"Komunikace"} size={1.5}>{value.komunikace}</RowItem>
                <RowItem label={"Parkování"} size={1.5}>{value.parkovani}</RowItem>
                <RowItem label={"Počet míst"} size={1} priority={9}>{value.pocetMist}</RowItem>
                <RowItem label={"Poslední závada"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniZavady)}</RowItem>
                <RowItem label={"Poslední údržba"} size={1.5}
                         priority={9}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
                <RowItem label={"Poznámka"} size={3} priority={9}>{value.poznamka}</RowItem>
            </Row>
        </ListItem>
    );
}
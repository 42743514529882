import {ApiState, createApiState, useApiState} from "@pasport/react-common";
import {useAppDispatch, useAppSelector} from "../index";
import {setApiState} from "../slices/apiCallsSlice";


export const useReduxApiState = <T, >(key: string) => {
    const apiCallState = useAppSelector<ApiState<T>>(state => (state.apiCalls.calls[key] || createApiState()) as ApiState<T>);
    const dispatch = useAppDispatch();


    return useApiState<T>({
        getter: () => apiCallState,
        setter: (value) => dispatch(setApiState({key, apiState: value}))
    });

}